import { AllowedCountriesPlugin } from '@lego/compliant-tracking-browser-plugin-allowed-countries';

let headerData;
let footerData;
let notificationData;
let cartCount = 0;
let cartDataCache = null;
let cartDataPromise = null;
let cartError = false;
let hostEnvironment;

function returnTemplateTagObject() {
	return {
			'{{countryName}}': returnEnv() == 'blapp' ? (blapp.country.mapCountry[blapp.session.codeCountryDetected] ? blapp.country.mapCountry[blapp.session.codeCountryDetected].strCountryName : 'USA') : (bl.country.mapCountry[bl.session.detected_country] ? bl.country.mapCountry[bl.session.detected_country].strCountryName : 'USA')
		,	'{{countryCode}}': returnEnv() == 'blapp' ? (blapp.country.mapCountry[blapp.session.codeCountryDetected] ? blapp.country.mapCountry[blapp.session.codeCountryDetected].codeCountry : 'US') : (bl.country.mapCountry[bl.session.detected_country] ? bl.country.mapCountry[bl.session.detected_country].idCountry : 'US')
		, '{{locale}}': 'en-us'
	};
}

function replaceTemplateTags(content, tagObject = returnTemplateTagObject()){
	var regex = new RegExp(Object.keys(tagObject).join("|"), "gi");

	return content.replace(regex, function(matched){
			return tagObject[matched];
	});
}

async function initPersonalization() {
	await fetchUserNotifications();
}

async function fetchBLPHeaderData() {
	await window.returnBLPHeaderData().then(val => {
			// console.log(val);
			if(val !== undefined) {
			headerData = val;

			if(isCookieEnabled('BLF')) {
				setLocalStorage('blpHeaderData', JSON.stringify({data: val, expiration: Date.now() + 600000}), 30);//10 * 60 * 1000;
			}
		}
	});
}

async function fetchBLPFooterData() {
	await window.returnBLPFooterData().then(val => {
		if(val !== undefined) {
			footerData = val;

			if(isCookieEnabled('BLF')) {
				setLocalStorage('blpFooterData', JSON.stringify({data: val, expiration: Date.now() + 600000}), 30);//10 * 60 * 1000
			}
		}
	});
}

async function initBLPHeaderData() {
	if(isCookieEnabled('BLF') && (getLocalStorage('blpHeaderData') !== undefined && getLocalStorage('blpHeaderData') !== null && JSON.parse(getLocalStorage('blpHeaderData')).data)) {
		headerData = JSON.parse(getLocalStorage('blpHeaderData')).data;

		if(JSON.parse(getLocalStorage('blpHeaderData')).expiration < Date.now()) {
			fetchBLPHeaderData();
		}
	}else{
		await fetchBLPHeaderData();
	}
}

async function initBLPFooterData() {
	if(isCookieEnabled('BLF') && (getLocalStorage('blpFooterData') !== undefined && getLocalStorage('blpFooterData') !== null && JSON.parse(getLocalStorage('blpFooterData')).data)) {
		footerData = JSON.parse(getLocalStorage('blpFooterData')).data;

		if(JSON.parse(getLocalStorage('blpFooterData')).expiration < Date.now()) {
			fetchBLPFooterData();
		}
	}else{
		await fetchBLPFooterData();
	}
}

async function initCartCount() {
	await fetchCartCount();
}

async function fetchEnvironment () {
	if(hostEnvironment == undefined) {
		await window.returnEnvironment().then(val => {
			hostEnvironment = val;

			return val;
		})
	}else{
		return hostEnvironment;
	}
}

async function fetchUserNotifications() {
	const isLoggedIn = returnIsLoggedIn();

	if(isLoggedIn) {
		await window.returnUserNotifications().then(val => {
			if(val && val.result) {
				notificationData = val.result;
			}else{
				notificationData = null;
			}
		}).catch(() => {
			notificationData = null;
		});
	}else{
		notificationData = null;
	}
	
	// console.log(notificationData);
}

async function fetchCartCount() {
	await window.returnCartCount().then(val => {
		if(val && val.result) {
			cartCount = val.result.cartCount;
		}else{
			cartCount = 0;
		}
	});
}

async function fetchCartData() {
	if (cartDataPromise) {
		return cartDataPromise;
	}
		
	cartDataPromise = window.returnCartSummary()
	.then(data => {
		cartDataCache = data.result;
		cartError = false;
		cartDataPromise = null;
	})
	.catch(() => {
		cartError = true;
		cartDataCache = null;
		cartDataPromise = null;
	});

	return cartDataPromise;
}

function returnMainNavData() {
	return headerData;
}

function initPlaceholder() {
	const blpHeader = document.querySelector('#js-blp-header');

	blpHeader.innerHTML = `
	<header class="blp-header blp-header--loading">
		<div class="blp-header__content">
			<a class="blp-header__logo" title="Home" href="//www.bricklink.com/"><img class="blp-header__logo-image" id="js-blp-logo" alt="BrickLink Logo" draggable="false" width="317" height="73" decoding="async" data-nimg="1" src="//static2.bricklink.com/img/bl_logo-horizontal.svg" style="color: transparent;"/></a>
			<nav class="blp-nav">
				<div style="position: relative; align-self:center">
					<ul class="blp-nav__main-items" data-orientation="horizontal" dir="ltr">
						<li class="blp-nav__main-item">
							<button class="blp-btn blp-nav__main-btn"></button>
						</li>
						<li class="blp-nav__main-item">
							<button class="blp-btn blp-nav__main-btn"></button>
						</li>
						<li class="blp-nav__main-item">
							<button class="blp-btn blp-nav__main-btn"></button>
						</li>
						<li class="blp-nav__main-item">
							<button class="blp-btn blp-nav__main-btn"></button>
						</li>
					</ul>
				</div>
			</nav>
			<div class="blp-search" id="js-blp-search">
				<div class="blp-adv-search__wrapper blp-adv-search__wrapper--desktop"></div>
			</div>
			<nav class="blp-icon-nav" id="js-blp-icon-nav">
				<div class="blp-icon-nav__item-container blp-icon-nav__item-container--search">
					<button class="blp-btn blp-icon-nav__item blp-icon-nav__item--search"></button>
				</div>
				<div class="blp-icon-nav__item-container blp-icon-nav__item-container--sign-in">
					<button class="blp-btn blp-icon-nav__item blp-icon-nav__item--sign-in"></button>
				</div>
				<div class="blp-icon-nav__item-container blp-icon-nav__item-container--cart">
					<button class="blp-btn blp-icon-nav__item blp-icon-nav__item--cart"></button>
				</div>
				<div class="blp-icon-nav__item-container blp-icon-nav__item-container--more">
					<button class="blp-btn blp-icon-nav__item blp-icon-nav__item--more"></button>
				</div>
			</nav>
		</div>
	</header>`;
}

function initBlankHeader() {
	const blpHeader = document.querySelector('#js-blp-header');

	blpHeader.innerHTML = `
	<header class="blp-header">
		<div class="blp-header__content">
			<a class="blp-header__logo" title="Home" href="//www.bricklink.com/"><img class="blp-header__logo-image" id="js-blp-logo" alt="BrickLink Logo" draggable="false" width="317" height="73" decoding="async" data-nimg="1" src="//static2.bricklink.com/img/bl_logo-horizontal.svg" style="color: transparent;"/></a>
		</div>
	</header>`;
}

async function initBLPHeader(callback) {
	const fontStylesheet = document.createElement('link');
				fontStylesheet.rel = 'stylesheet';
				fontStylesheet.href = 'https://assets.lego.com/fonts/latest/typewell-pro.css';
				fontStylesheet.type = 'text/css';
	
	document.head.appendChild(fontStylesheet);

	if(!getLocalStorage('blpHeaderData') || getLocalStorage('blpHeaderData') === undefined || getLocalStorage('blpHeaderData') === null || !JSON.parse(getLocalStorage('blpHeaderData')).data) {
		const blpHeader = document.querySelector('#js-blp-header');
		initPlaceholder();
		blpHeader.classList.add('blp-header--fade-in');
	}else{
		initBlankHeader();
	}

	await initBLPHeaderData();
	await initPersonalization();
	await initCartCount();

	renderHeader();
	handleHover();
	handleIconNav();
	handleSearch();
	handleIconNavNotifications();

	buildSitewideNotifications();

	if(callback) {
		callback();
	}

	if (cartCount > 0) {
		// fetchCartData();
		const cartIcon = document.getElementById('js-notification-cart');
		cartIcon.classList.remove('blp-icon-nav__item-notification--hidden');
		cartIcon.textContent = cartCount;
	}
}

async function initBLPFooter() {
	await initBLPFooterData();

	renderFooter();
}

function renderHeader() {
	const blpHeader = document.querySelector('#js-blp-header');
	const header = document.createElement('header');
				header.classList.add('blp-header', 'blp-header--loaded');
	
	const headerContent = document.createElement('div');
				headerContent.classList.add('blp-header__content');
	
	const headerSearchContainer = document.createElement('div');
				headerSearchContainer.classList.add('blp-search');
				headerSearchContainer.id = 'js-blp-search';

	headerSearchContainer.appendChild(returnAdvancedSearchBar());

	// headerSearchContainer.appendChild(returnSearchBar());

	headerContent.appendChild(returnLogo());
	headerContent.appendChild(returnMainNav());
	headerContent.appendChild(headerSearchContainer);
	headerContent.appendChild(returnIconNav());

	const sitewideNotificationContainer = document.createElement('section');
				sitewideNotificationContainer.classList.add('blp-sitewide-notification');
				sitewideNotificationContainer.id = 'js-sitewide-notification';


	header.appendChild(sitewideNotificationContainer);
	header.appendChild(headerContent);

	blpHeader.innerHTML = '';
	blpHeader.appendChild(header);
}

const sitewideNotificationContent = [
		{id: 'daily_maintenance', content: {id: 'dailyMaintenance', headline: 'Daily Maintenance', headlineMob: false, message: 'Starting soon at 01:50 AM {{maintenance_timezone}}. The site will be unavailable for 10 minutes.', messageMob: false, action: false}}
	, {id: 'monthly_maintenance', content: {id: 'monthlyMaintenance', headline: 'Monthly Maintenance', headlineMob: false, message: 'The site will be down for scheduled monthly maintenance on {{monthly_maintenance_date}} from 01:00 AM to 02:00 AM {{maintenance_timezone}}.', messageMob: false, action: false}}
	, {id: 'temporary_maintenance', content: {id: 'extendedMaintenance', headline: 'Extended Maintenance', headlineMob: false, message: 'The site will be down for extended maintenance from 23:30 PM to {{temporary_maintenance_date}} 02:00 AM {{maintenance_timezone}}.', messageMob: false, action: false}}
	, {id: 'paypal_switch', content: {id: 'actionRequired', headline: 'Action Required', headlineMob: false, message: 'Please update your PayPal onsite payment method by going to your store settings.', messageMob: false, action: {link: '/v2/mystore/display.page', text: 'Go to settings page'}}}
	, {id: 'buyer_revoked', content: {id: 'privilegesRevoked', headline: 'Privileges Revoked', headlineMob: false, message: 'Your buyer privileges have been revoked.', messageMob: false, action: {link: '/helpDesk.asp?helpDeskID=-1', text: 'Contact Help Desk'}}}
	, {id: 'seller_revoked', content: {id: 'storeSuspended', headline: 'Store Suspended', headlineMob: false, message: 'Your store has been suspended.', messageMob: false, action: {link: '/helpDesk.asp?helpDeskID=-1', text: 'Contact Help Desk'}}}
	, {id: 'late_fee', content: {id: 'feePaymentDue', headline: 'Fee Payment Due', headlineMob: false, message: 'We haven’t received your fee payment.', messageMob: false, action: {link: '/account.asp#A', text: 'Go to my fee page'}}}
	, {id: 'non_shipping_seller', content: {id: 'nonShippingSellerAlert', headline: 'Non Shipping Seller Alert', headlineMob: 'NSS Alert', message: 'You’ve received an NSS.', messageMob: 'You’ve received a Non Shipping Seller alert.', action: {link: '/orderReceived.asp?st=s', text: 'See details'}}}
	, {id: 'non_responding_seller', content: {id: 'nonRespondingSellerAlert', headline: 'Non Responding Seller Alert', headlineMob: 'NRS Alert', message: 'You’ve received an NRS.', messageMob: 'You’ve received a Non Responding Seller alert.', action: {link: '/orderReceived.asp?st=r', text: 'See details'}}}
	, {id: 'non_paying_buyer', content: {id: 'nonPayingBuyerAlert', headline: 'Non Paying Buyer Alert', headlineMob: 'NPB Alert', message: 'You’ve received an NPB.', messageMob: 'You’ve received a Non Paying Buyer alert.', action: {link: '/orderPlaced.asp?st=n', text: 'See details'}}}
	, {id: 'tmp_blocked', content: {id: 'activityBlocked', headline: 'Activity Blocked', headlineMob: false, message: 'Your BrickLink activity has been temporarily blocked.', messageMob: false, action: {link: '{{tmp_blocked_announcement}}', text: 'See announcement'}}}
	, {id: 'tos_updated', content: {id: 'bricklinkToSUpdated', headline: 'BrickLink ToS Updated', headlineMob: false, message: 'We’ve updated our Terms of Service.', messageMob: false, action: {link: '/v3/terms_of_service.page', text: 'See details'}}}
];

function buildSitewideNotifications() {
	window.returnSiteWideNotifications().then(data => {
		// console.log(data.result);
		if(data && data.result) {
			const activeNotifications = sitewideNotificationContent.filter((item) => {
				return data.result[item.id] == true;
			});

			const templateTags = {
					'{{maintenance_timezone}}': data.result.maintenance_timezone
				,	'{{monthly_maintenance_date}}': data.result.monthly_maintenance_date
				,	'{{temporary_maintenance_date}}': data.result.temporary_maintenance_date
				,	'{{tmp_blocked_announcement}}': data.result.tmp_blocked_announcement
			};

			if(activeNotifications.length) {
				const sitewideNotificationContainer = document.getElementById('js-sitewide-notification');
		
				const sitewideNotificationItems = document.createElement('div');
							sitewideNotificationItems.classList.add('blp-sitewide-notification__items');
				
				activeNotifications.forEach((item) => {
					if(getCookie(`disablealert_${item.content.id}`)) {
						return;
					}
		
					sitewideNotificationItems.appendChild(returnSitewideNotificationItem(item, templateTags));
				});
		
				sitewideNotificationContainer.appendChild(sitewideNotificationItems);
		
				handleSiteWideNotifications();
			}
		}
	});
}

function returnSitewideNotificationItem(notification, templateTags) {
	const notificationContent = notification.content;
	
	let notificationHeadlineMob, notificationMessageMob;

	const notificationItem = document.createElement('div');
				notificationItem.classList.add('blp-sitewide-notification__item', `blp-sitewide-notification__item--${notificationContent.id}`);
				notificationItem.dataset.id = notificationContent.id;
				notificationItem.dataset.state = 'closed';
	
	const notificationItemContent = document.createElement('div');
				notificationItemContent.classList.add('blp-sitewide-notification__item-content');

	const notificationHeadline = document.createElement('strong');
				notificationHeadline.classList.add('blp-sitewide-notification__item-headline');
				notificationHeadline.textContent = notificationContent.headline + ': ';
	const notificationMessage = document.createElement('span');
				notificationMessage.classList.add('blp-sitewide-notification__item-message');
				notificationMessage.innerHTML = `${replaceTemplateTags(notificationContent.message, templateTags)} ${notificationContent.action ? `(<a href="${replaceTemplateTags(notificationContent.action.link, templateTags)}" class="blp-sitewide-notification__item-action">${replaceTemplateTags(notificationContent.action.text, templateTags)}</a>)` : ''}`;
	
	const notificationClose = document.createElement('button');
				notificationClose.classList.add('blp-sitewide-notification__item-close');
				notificationClose.addEventListener('click', () => closeSitewideNotification(notificationItem));
				notificationClose.textContent = 'Close';
	const notificationCloseIcon = returnIconSvg('circle-xmark-solid', 'sm');

	const notificationCloseMob = document.createElement('button');
				notificationCloseMob.classList.add('blp-sitewide-notification__item-close-mob');
				notificationCloseMob.addEventListener('click', () => closeSitewideNotification(notificationItem));
				notificationCloseMob.textContent = 'Close Notification';

	notificationClose.appendChild(notificationCloseIcon);

	const notificationToggle = document.createElement('button');
				notificationToggle.classList.add('blp-sitewide-notification__item-toggle');
				notificationToggle.addEventListener('click', () => toggleSitewideNotification(notificationItem));
	const notificationToggleText = document.createElement('span');
				notificationToggleText.classList.add('blp-sitewide-notification__item-toggle-text');
				notificationToggleText.textContent = 'Show more';
	const notificationToggleIcon = returnAnimatedChevron(['blp-sitewide-notification__item-toggle-icon']);

	notificationToggle.appendChild(notificationToggleText);
	notificationToggle.appendChild(notificationToggleIcon);

	notificationHeadlineMob = document.createElement('strong');
	notificationHeadlineMob.classList.add('blp-sitewide-notification__item-headline', 'blp-sitewide-notification__item-headline--mob');

	if(notificationContent.headlineMob) {
		notificationHeadlineMob.textContent = replaceTemplateTags(notificationContent.headlineMob, templateTags);
	}else{
		notificationHeadlineMob.textContent = replaceTemplateTags(notificationContent.headline, templateTags);
	}

	notificationItemContent.appendChild(notificationHeadlineMob);
	notificationItemContent.appendChild(notificationHeadline);

	if(notificationContent.messageMob) {
		notificationMessageMob = document.createElement('span');
		notificationMessageMob.classList.add('blp-sitewide-notification__item-message', 'blp-sitewide-notification__item-message--mob');
		notificationMessageMob.innerHTML = `${replaceTemplateTags(notificationContent.messageMob, templateTags)} ${notificationContent.action ? `(<a href="${replaceTemplateTags(notificationContent.action.link, templateTags)}" class="blp-sitewide-notification__item-action">${replaceTemplateTags(notificationContent.action.text, templateTags)}</a>)` : ''}`;

		notificationItemContent.appendChild(notificationMessageMob);
		notificationItemContent.appendChild(notificationMessage);
	}else{
		notificationItemContent.appendChild(notificationMessage);
	}

	notificationItemContent.appendChild(notificationToggle);
	notificationItem.appendChild(notificationItemContent);
	notificationItem.appendChild(notificationClose);
	notificationItem.appendChild(notificationCloseMob);

	return notificationItem;
}

function closeSitewideNotification(item) {
	setCookie(`disablealert_${item.dataset.id}`, true, 1);
	item.remove();

	const notificationContainer = document.getElementById('js-sitewide-notification');
	const notificationItems = notificationContainer.querySelectorAll('.blp-sitewide-notification__item');

	if(notificationItems.length > 0) {
		document.querySelector('html').style.setProperty('--bl-sitewide-notification-height', `${notificationItems[0].getBoundingClientRect().height}px`);
	}else{
		document.querySelector('html').style.setProperty('--bl-sitewide-notification-height', '0px');
		notificationContainer.remove();
	}
}

function toggleSitewideNotification(item) {
	const itemState = item.dataset.state;
	const notificationContainer = document.getElementById('js-sitewide-notification');
	const notificationItems = notificationContainer.querySelectorAll('.blp-sitewide-notification__item');

	if(itemState == 'closed') {
		item.dataset.state = 'open';
		item.querySelector('.blp-sitewide-notification__item-toggle-text').textContent = 'Show less';
		document.querySelector('html').style.setProperty('--bl-sitewide-notification-height', `${notificationItems[0].getBoundingClientRect().height}px`);
	}else{
		item.dataset.state = 'closed';
		item.querySelector('.blp-sitewide-notification__item-toggle-text').textContent = 'Show more';
		document.querySelector('html').style.setProperty('--bl-sitewide-notification-height', `${notificationItems[0].getBoundingClientRect().height}px`);
	}
}

function handleSiteWideNotifications() {
	const notificationContainer = document.getElementById('js-sitewide-notification');
	const notificationContainerItems = document.querySelector('.blp-sitewide-notification__items');
	let notificationItems = notificationContainer.querySelectorAll('.blp-sitewide-notification__item');
	
	const speed = 200;
	const firstInterval = 3000;
	const interval = 10000;
	let first = true;

	function scrollNotification() {
		notificationItems = notificationContainer.querySelectorAll('.blp-sitewide-notification__item');
		
		if(notificationItems.length > 1) {
			setTimeout(() => {
				notificationItems = notificationContainer.querySelectorAll('.blp-sitewide-notification__item');
				if(notificationItems.length > 1) {
					document.querySelector('html').style.setProperty('--bl-sitewide-notification-height', `${notificationItems[1].getBoundingClientRect().height}px`);
					notificationContainer.classList.add('blp-sitewide-notification--animating');

					setTimeout(() => {
						notificationContainer.classList.remove('blp-sitewide-notification--animating');
						notificationContainerItems.appendChild(notificationItems[0]);
					}, speed);

					if(first) {
						first = false;
					}

					scrollNotification();
				}
			}, first ? firstInterval : interval);
		}
	}

	if(notificationItems.length > 0) {
		document.querySelector('html').style.setProperty('--bl-sitewide-notification-height', `${notificationItems[0].getBoundingClientRect().height}px`);

		scrollNotification();
		watchNotificationResize();
	}
}

function watchNotificationResize() {
	window.addEventListener('resize', () => {
		const notificationContainer = document.getElementById('js-sitewide-notification');
		const notificationItems = notificationContainer.querySelectorAll('.blp-sitewide-notification__item');

		if(notificationItems.length > 0) {
			document.querySelector('html').style.setProperty('--bl-sitewide-notification-height', `${notificationItems[0].getBoundingClientRect().height}px`);
		}
	});
}

function returnLogo() {
	const logo = document.createElement('a');
				logo.classList.add('blp-header__logo');
				logo.title = 'Home';
				logo.href = '//www.bricklink.com/';
	const logoImage = document.createElement('img');
				logoImage.classList.add('blp-header__logo-image');
				logoImage.id = 'js-blp-logo';
				logoImage.alt = 'BrickLink Logo';
				logoImage.draggable = false;
				logoImage.fetchpriority = 'high';
				logoImage.width = 317;
				logoImage.height = 73;
				logoImage.decoding = 'async';
				logoImage.dataset.nimg = 1;
				logoImage.style.color = 'transparent';
				logoImage.src = '//static2.bricklink.com/img/bl_logo-horizontal.svg';

	logo.appendChild(logoImage);

	return logo;
}

function returnMainNav() {
	const mainNav = document.createElement('nav');
				mainNav.classList.add('blp-nav');
				mainNav.setAttribute('aria-label', 'Primary links');
				mainNav.setAttribute('data-orientation', 'horizontal');
				mainNav.dir = 'ltr';
				mainNav.id = 'js-blp-nav';
				mainNav.setAttribute('aria-hidden', 'false');
				mainNav.style.setProperty('--bl-navigation-header-logo-width', '157.171875px');

	const mainNavContentWrapper = document.createElement('div');
				mainNavContentWrapper.style.position = 'relative';

	const mainNavIndicator = document.createElement('div');
				mainNavIndicator.setAttribute('aria-hidden', 'true');
				mainNavIndicator.setAttribute('data-state', 'hidden');
				mainNavIndicator.setAttribute('data-orientation', 'horizontal');
				mainNavIndicator.id = 'js-blp-hover-indicator';
				mainNavIndicator.classList.add('blp-nav__indicator');
				mainNavIndicator.style.position = 'absolute';
				mainNavIndicator.style.left = '0px';
				mainNavIndicator.style.width = '109px';
				mainNavIndicator.style.transform = 'translateX(508px)';

	const mainNavData = returnMainNavData().navigationMenu;

	const mainNavList = document.createElement('ul');
				mainNavList.classList.add('blp-nav__main-items');
				mainNavList.setAttribute('data-orientation', 'horizontal');
				mainNavList.dir = 'ltr';

	const mainNavDropdown = document.createElement('div');
				mainNavDropdown.classList.add('blp-nav-dropdown');

	const mainNavDropdownWrapper = document.createElement('div');
				mainNavDropdownWrapper.classList.add('blp-nav-dropdown__wrapper');

	const mainNavDropdownContainer = document.createElement('div');
				mainNavDropdownContainer.classList.add('blp-nav-dropdown__container');
				mainNavDropdownContainer.setAttribute('data-state', 'closed');
				mainNavDropdownContainer.setAttribute('data-orientation', 'horizontal');
				mainNavDropdownContainer.setAttribute('data-content', '');
				mainNavDropdownContainer.style.pointerEvents = 'none';

	const mainNavDropdownContent = document.createElement('div');
				mainNavDropdownContent.classList.add('blp-nav-dropdown__content');

	mainNavDropdownContainer.appendChild(mainNavDropdownContent);
	mainNavDropdownWrapper.appendChild(mainNavDropdownContainer);
	mainNavDropdown.appendChild(mainNavDropdownWrapper);

	mainNavData.forEach((item) => {
		mainNavList.appendChild(returnMainNavItem(item));
	});

	mainNavContentWrapper.appendChild(mainNavList);
	mainNavContentWrapper.appendChild(mainNavIndicator);

	mainNav.appendChild(mainNavContentWrapper);
	mainNav.appendChild(mainNavDropdown);
	
	return mainNav;
}

function returnIconNavData() {
	const isLoggedIn = returnIsLoggedIn();
	const isSeller = returnIsSeller();

	const iconNavData = returnMainNavData().iconLinks;
	let orderedIconNavData = [iconNavData.search];

	if(isLoggedIn) {
		if(isSeller) {
			orderedIconNavData.push(iconNavData.sell);
		}
		orderedIconNavData.push(iconNavData.profile);
	}else{
		orderedIconNavData.push(iconNavData.logIn);
	}

	orderedIconNavData.push(iconNavData.cart);
	orderedIconNavData.push(iconNavData.more);

	return orderedIconNavData;
}

function returnIconNav() {
	const iconNavData = returnIconNavData();
	
	const iconNav = document.createElement('nav');
				iconNav.classList.add('blp-icon-nav');
				iconNav.id = 'js-blp-icon-nav';
	
	iconNavData.forEach((item) => {
		const iconNavItemContainer = document.createElement('div');
					iconNavItemContainer.classList.add('blp-icon-nav__item-container', `blp-icon-nav__item-container--${item.children.toLowerCase().replace(/ /g, '-')}`);

		const iconNavItem = document.createElement('button');
					iconNavItem.classList.add('blp-btn', 'blp-icon-nav__item', `blp-icon-nav__item--${item.children.toLowerCase().replace(/ /g, '-')}`);
					iconNavItem.id = `js-trigger-${item.children.toLowerCase().replace(/ /g, '-')}`;
					iconNavItem.setAttribute('aria-haspopup', 'dialog');
					iconNavItem.setAttribute('aria-expanded', 'false');
					iconNavItem.dataset.state = 'closed';
		const iconNavItemIconNotificationGroup = document.createElement('div');
					iconNavItemIconNotificationGroup.classList.add('blp-icon-nav__item-icon-notification-group');
		const iconNavItemNotification = document.createElement('span');
					iconNavItemNotification.classList.add('blp-icon-nav__item-notification', 'blp-icon-nav__item-notification--hidden');
					iconNavItemNotification.id = `js-notification-${item.children.toLowerCase().replace(/ /g, '-')}`;
					
		iconNavItemIconNotificationGroup.appendChild(iconNavItemNotification);

		if(item.children.toLowerCase() == 'profile') {
			const iconNavItemImageContainer = document.createElement('div');
						iconNavItemImageContainer.classList.add('blp-icon-nav__item-image-container');
			const iconNavItemImage = document.createElement('img');
						iconNavItemImage.classList.add('blp-icon-nav__item-image');
						iconNavItemImage.src = returnEnv() == 'blapp' ? blapp.session.getMyProfileURL("medium") : bl.session.user.user_profile_url_m;
						iconNavItemImage.alt = `${returnEnv() == 'blapp' ? blapp.session.me.skUsername : bl.session.user.user_id} Profile Image`;
			
			iconNavItemImageContainer.appendChild(iconNavItemImage);
			iconNavItemIconNotificationGroup.appendChild(iconNavItemImageContainer);
		}else{
			const iconNavIcon = returnIconSvg(item.icon, 'large');
		
			iconNavItemIconNotificationGroup.appendChild(iconNavIcon);
		}

		iconNavItem.appendChild(iconNavItemIconNotificationGroup);
		iconNavItemContainer.appendChild(iconNavItem);
		iconNav.appendChild(iconNavItemContainer);
	});

	return iconNav;
}

function returnIconSvg(icon, size = null, classes = []) {
	const iconSvg = document.createElement('div');
				iconSvg.classList.add('blp-icon', ...classes, size ? `blp-icon--${size}` : '');
				iconSvg.style.setProperty('--bl-headless-icon-url', `url('https://assets.lego.com/icons/v7.0.0/${icon}.svg')`);
				iconSvg.setAttribute('aria-hidden', 'true');

	return iconSvg;
}

function handleHover() {
	const indicator = document.getElementById('js-blp-hover-indicator');
	const mainItems = document.querySelectorAll('.blp-nav__main-item');
	const dropdown = document.querySelector('.blp-nav-dropdown__container');
	const dropdownWrapper = document.querySelector('.blp-nav-dropdown__wrapper');
	const nav = document.getElementById('js-blp-nav');

	let activeItem = null;

	let dropdownHovered = false;

	let activateHover = false;
	let itemHovered = false;

	nav.addEventListener('mouseenter', () => {
		activateHover = true;
	});

	nav.addEventListener('mouseleave', () => {
		activateHover = false;
		itemHovered = false;
	});

	mainItems.forEach((item) => {
		let itemHoveredTimeout = null;

		let subItemHoveredTimeout = null

		item.addEventListener('mouseenter', () => {
			// if(activateHover && itemHovered) {
			// 	menuItemMouseEnter(item);
			// }else{
				itemHoveredTimeout = setTimeout(() => {
					itemHovered = true;

					menuItemMouseEnter(item);
				}, 300);
			// }
		});

		item.addEventListener('mouseleave', () => {
			clearTimeout(itemHoveredTimeout);
			setTimeout(() => {
				if(!dropdownHovered) {
					item.querySelector('.blp-nav__main-btn').dataset.state = 'closed';
					indicator.dataset.state = 'hidden';
					toggleDropdown(item, activeItem);
				}
			}, 300);
		});
	});

	function menuItemMouseEnter(item){
		const rect = item.getBoundingClientRect();
		const offset = rect.left - indicator.parentElement.getBoundingClientRect().left;
		indicator.style.transform = `translateX(${offset}px)`;
		indicator.style.width = `${rect.width}px`;
		indicator.dataset.state = 'visible';

		item.querySelector('.blp-nav__main-btn').dataset.state = 'open';

		activeItem = item.querySelector('.blp-nav__main-btn').id.replace('js-trigger-', '');

		document.querySelectorAll(`.blp-nav__main-btn:not(#${item.querySelector('.blp-nav__main-btn').id})`).forEach((item) => {
			item.dataset.state = 'closed';
		});
		
		if(!dropdownHovered) {
			toggleDropdown(item, activeItem, true);
		}
	}

	dropdown.addEventListener('mouseenter', () => {
		dropdown.style.pointerEvents = 'auto';
		dropdown.dataset.state = 'open';

		dropdownHovered = true;

		const currentContent = dropdown.dataset.content;

		if(currentContent) {
			indicator.dataset.state = 'visible';
			const item = document.getElementById(`js-trigger-${currentContent}`);
			item.dataset.state = 'open';

			document.querySelectorAll(`.blp-nav__main-btn:not(#${item.id})`).forEach((item) => {
				item.dataset.state = 'closed';
			});
		}
	});

	dropdownWrapper.addEventListener('mouseenter', () => {
		dropdownHovered = true;
	});
	dropdownWrapper.addEventListener('mouseleave', () => {
		dropdownHovered = false;
	});

	nav.addEventListener('mouseleave', () => {
		indicator.dataset.state = 'hidden';
		dropdown.style.pointerEvents = 'none';
		dropdown.dataset.state = 'closed';

		document.querySelectorAll('.blp-nav__main-btn').forEach((item) => {
			item.dataset.state = 'closed';
		});
	});
}

function toggleDropdown(item, activeItem, clear) {
	const dropdownContainer = document.querySelector('.blp-nav-dropdown__container');
	const mainBtn = item.querySelector('.blp-nav__main-btn');
	const mainBtnState = mainBtn.dataset.state;

	const logo = document.getElementById('js-blp-logo');
	const mainNav = document.getElementById('js-blp-nav');
				mainNav.style.setProperty('--bl-navigation-header-logo-width', `${logo.getBoundingClientRect().width}px`);

	dropdownContainer.dataset.content = activeItem;

	if(clear) {
		clearSubNavContent();
	}

	renderSubNavContent(mainBtn, !clear);

	if(mainBtnState === 'open') {
		dropdownContainer.style.pointerEvents = 'auto';
		dropdownContainer.dataset.state = 'open';
	}else{
		dropdownContainer.style.pointerEvents = 'none';
		dropdownContainer.dataset.state = 'closed';
	}
}

function clearSubNavContent() {
	const dropdown = document.querySelector('.blp-nav-dropdown__content');
	dropdown.innerHTML = '';
}

function renderSubNavContent(item, clear) {
	if(clear) {
		return;
	}

	const dropdownContainer = document.querySelector('.blp-nav-dropdown__container');
	const dropdown = document.querySelector('.blp-nav-dropdown__content');
	const subNavData = returnSubNavData(item.id.replace('js-trigger-', '').replace(/-/g, ' ')).content;

	const groupElement = returnGroup();

	subNavData.forEach((column) => {
		const columnElement = returnColumn();
		const columnList = returnColumnList();
		const columnLabel = document.createElement('h2');
					columnLabel.classList.add('blp-nav-dropdown__column-label');	
					columnLabel.textContent = replaceTemplateTags(column.label);	
		
		column.content.forEach((item) => {
			const itemElement = returnColumnItem(item);

			columnList.appendChild(itemElement);
		});

		columnElement.appendChild(columnLabel);
		columnElement.appendChild(columnList);

		groupElement.appendChild(columnElement);
	});

	dropdown.dataset.content = item.id.replace('js-trigger-', '');
	dropdown.appendChild(groupElement);
	// dropdown.appendChild(returnDivider());
	// dropdown.appendChild(returnColumnLink({children: 'Help & Support', href: '', icon: 'circle-question'}));
	
	dropdownContainer.style.setProperty('--blp-nav-dropdown-height', `${dropdown.getBoundingClientRect().height}px`);
}

function returnGroup() {
	const group = document.createElement('div');
	group.classList.add('blp-nav-dropdown__group');

	return group;
}

function returnColumn() {
	const column = document.createElement('div');
	column.classList.add('blp-nav-dropdown__column');

	return column;
}

function returnColumnList() {
	const columnList = document.createElement('ul');
	columnList.classList.add('blp-nav-dropdown__column-list');

	return columnList;
}

function returnColumnItem(item)	{
	const columnItem = document.createElement('li');
				columnItem.classList.add('blp-nav-dropdown__column-item');

	const columnItemLink = returnColumnLink(item);

	columnItem.appendChild(columnItemLink);

	return columnItem;
}

function returnColumnLink(item) {
	let columnLink;

	if(item.onClick) {
		columnLink = document.createElement('button');
		columnLink.addEventListener('click', () => item.onClick());
	}else{
		columnLink = document.createElement('a');
		columnLink.href = replaceTemplateTags(item.href);
	}

	columnLink.classList.add('blp-nav-dropdown__column-link');
	columnLink.dataset.content = item.children.toLowerCase().replace(/ /g, '-');
	columnLink.dataset.id = item.uid;

	if(item.icon) {
		const columnLinkIcon = returnIconSvg(item.icon, 'md', ['blp-nav-dropdown__column-link-icon']);

		const columnLinkContent = document.createElement('span');
					columnLinkContent.textContent = replaceTemplateTags(item.children);

		columnLink.appendChild(columnLinkIcon);
		columnLink.appendChild(columnLinkContent);
	}else{
		columnLink.textContent = replaceTemplateTags(item.children);
	}

	if(item.newTab) {
		columnLink.target = '_blank';

		const externalLinkIcon = returnIconSvg('arrow-up-right-from-square', 'sm');
		columnLink.appendChild(externalLinkIcon);
	}

	if(item.notification) {
		const columnLinkNotification = document.createElement('span');
					columnLinkNotification.classList.add('blp-nav-dropdown__column-link-notification');
					columnLinkNotification.dataset.profile = item.notification.notification_badge.profile_counts ? item.notification.notification_badge.profile_counts : '';
					columnLinkNotification.dataset.store = item.notification.notification_badge.store_counts ? item.notification.notification_badge.store_counts : '';
		
		columnLink.appendChild(columnLinkNotification);
	}

	return columnLink;
}

function returnDivider(classes = []) {
	const divider = document.createElement('hr');
	divider.classList.add('blp-nav-dropdown__divider', ...classes);

	return divider;
}

function handleIconNav() {
	const iconNav = document.getElementById('js-blp-icon-nav');
	const iconNavItems = iconNav.querySelectorAll('.blp-icon-nav__item');

	iconNavItems.forEach((item) => {
		item.addEventListener('click', () => {
			if(item.dataset.state == 'open') {
				closeUserNavContent(item);
			}else{
				iconNavItems.forEach((item) => {
					closeUserNavContent(item);
				});

				item.dataset.state = 'open';

				const itemId = item.id.replace('js-trigger-', '');

				window.addEventListener('click', (e) => userNavClickOut(e));
				renderUserNavContent(item, itemId);
			}
		});
	});
}

function userNavClickOut(e) {
	const userNav = document.getElementById('js-blp-icon-nav');

	if(!userNav.contains(e.target)) {
		const userNavItems = document.querySelectorAll('.blp-icon-nav__item');

		userNavItems.forEach((item) => {
			closeUserNavContent(item);
		});
	}
}

function renderUserNavContent(item, id) {
	switch(id) {
		case 'cart':
			return renderUserNavContainer(item, returnUserCartContainer(), ['blp-cart-information']);
		
		case 'sign-in':
			// console.log('log-in');
			loginLogout('login');
			break;
		
		case 'search':
			// console.log('search');
			return renderSearchNavContainer(item);
		
		case 'more':
			// console.log('more')
			return renderUserNavContainer(item, returnBrowseNavContent(item), ['blp-browse-nav']);
		
		case 'profile':
			// console.log('profile');
			return renderUserNavContainer(item, returnUserNavContent('profileInformation'));

		case 'sell':
			// console.log('sell');
			return renderUserNavContainer(item, returnUserNavContent('storeInformation'));
		}
}

function closeMobileNav(item) {
	const itemParent = item.parentElement;
	const itemNav = itemParent.querySelector('.blp-user-nav');
				itemNav.classList.remove('blp-user-nav--opening');
				itemNav.classList.add('blp-user-nav--closing');
	
	setTimeout(() => {
		closeUserNavContent(item);
	}, 300);
}

function renderUserNavContainer(item, content, classes = false) {
	const itemParent = item.parentElement;

	const userNavMenu = document.createElement('div');
				userNavMenu.classList.add('blp-user-nav');
				if(classes) {
					userNavMenu.classList.add(...classes);
				}
				setTimeout(() => {
					userNavMenu.classList.add('blp-user-nav--opening');
				}, 100);
	const userNavContainer = document.createElement('div');
				userNavContainer.classList.add('blp-user-nav__container');
				userNavContainer.dataset.page = 1;
	
	const userNavTopBar = document.createElement('div');
				userNavTopBar.classList.add('blp-user-nav__top-bar');

	const userNavClose = document.createElement('button');
				userNavClose.classList.add('blp-user-nav__close');
				userNavClose.addEventListener('click', () => closeMobileNav(item));
	const userNavCloseIcon = returnIconSvg('control-close', 'large');

	const navOverlay = returnNavOverlay(item);

	userNavClose.appendChild(userNavCloseIcon);
	userNavTopBar.appendChild(userNavClose);
	userNavContainer.appendChild(userNavTopBar);
	userNavContainer.appendChild(content);
	userNavMenu.appendChild(navOverlay);
	userNavMenu.appendChild(userNavContainer);
	itemParent.appendChild(userNavMenu);

	const itemId = item.id.replace('js-trigger-', '');
	
	if(itemId === 'cart') {
		renderCartContent();
	}

	handleUserNavActions();
	handleUserNavNotifications();
	handleIconNavNotifications();
}

function handleUserNavActions() {
	const logoutButton = document.querySelectorAll('.blp-nav-dropdown__column-link[data-id="blt2c6d894180d92b5e"]');
	
	if(logoutButton) {	
		logoutButton.forEach((item) => {
			item.addEventListener('click', (e) => {e.preventDefault(); loginLogout('logout')});	
		});		
	}
}

function handleIconNavNotifications() {
	const notificationMore = document.getElementById('js-notification-more');
	const notificationStore = document.getElementById('js-notification-sell');
	const notificationProfile = document.getElementById('js-notification-profile');
	const notificationProfileMobile = document.getElementById('js-notification-profile-mobile');

	if(notificationData) {
		const notificationCountProfile = notificationData.mybl.ordersToPay + notificationData.mybl.messageCount;
		const notificationCountStore = notificationData.mystore.ordersIncomplete + notificationData.mystore.quotesIncomplete + notificationData.mystore.ordersToInvoice + notificationData.mystore.ordersToDrivethru + notificationData.mystore.ordersToFeedback + notificationData.mystore.lotsForNotification + (notificationData.mystore.lateFee ? 1 : 0) + (notificationData.mystore.nonShippingSeller ? 1 : 0) + (notificationData.mystore.nonRespondingSeller ? 1 : 0) + notificationData.mystore.ordersToShip + notificationData.mystore.ordersToCancel;
		const notificationCountMore = notificationCountProfile;

		if(notificationCountProfile > 0) {
			notificationProfile.classList.remove('blp-icon-nav__item-notification--hidden');
			notificationProfile.textContent = notificationCountProfile;
		}
		if(notificationCountStore > 0) {
			notificationStore.classList.remove('blp-icon-nav__item-notification--hidden');
			notificationStore.textContent = notificationCountStore;
		}
		if(notificationCountMore > 0) {
			notificationMore.classList.remove('blp-icon-nav__item-notification--hidden');
			notificationMore.textContent = notificationCountMore;
		}
		if(notificationProfileMobile && notificationCountProfile > 0) {
			notificationProfileMobile.classList.add('blp-nav-dropdown__column-link-notification--active');
			notificationProfileMobile.textContent = notificationCountProfile;
		}
	}
}

const notificationText = {
	lateFee: {text: 'Fee payment not received', link: '/v2/mystore/fee.page'},
	nonShippingSeller: {text: 'Non Shipping Seller Alert', link: '/orderReceived.asp?st=s&orderFiled=A'},
	nonRespondingSeller: {text: 'Non Responding Seller Alert', link: '/orderReceived.asp?st=r&orderFiled=A'},
	ordersToShip: {singular: 'Order not yet shipped', plural: 'Orders not yet shipped', link: '/orderReceived.asp?viewShip=Y&orderFiled=A'},
	ordersToCancel: {singular: 'Order cancel request', plural: 'Order cancel requests', link: '/orderReceived.asp?st=c&orderFiled=A'}
}

function handleUserNavNotifications() {
	const userNavContainerItems = document.querySelectorAll('.blp-user-nav__container .blp-nav-dropdown__column-link-notification');

	if(!notificationData) {
		return;
	}

	userNavContainerItems.forEach((item) => {
		const profileData = item.dataset.profile;
		const storeData = item.dataset.store;

		if(profileData != null) {
			const notificationValue = notificationData.mybl[profileData];
			if(notificationValue) {
				item.innerHTML = notificationData.mybl[profileData];
				item.classList.add('blp-nav-dropdown__column-link-notification--active');
			}
		}
		
		if(storeData != null) {
			const notificationValue = notificationData.mystore[storeData];
			if(notificationValue) {
				item.innerHTML = notificationData.mystore[storeData];
				item.classList.add('blp-nav-dropdown__column-link-notification--active');
			}
		}
	});

	const userNavNotificationZone = document.getElementById('js-nav-dropdown-notification-zone');
	const userNavNotificationZoneContent = document.getElementById('js-nav-dropdown-notification-zone-content');

	if(userNavNotificationZone) {
		let hasNotifications = false;

		if(notificationData.mystore.lateFee) {
			const notification = returnNotificationItem(notificationText.lateFee.text, notificationText.lateFee.link, 'red');
			userNavNotificationZoneContent.appendChild(notification);
			hasNotifications = true;
		}
		if(notificationData.mystore.nonShippingSeller) {
			const notification = returnNotificationItem(notificationText.nonShippingSeller.text, notificationText.nonShippingSeller.link, 'red');
			userNavNotificationZoneContent.appendChild(notification);
			hasNotifications = true;
		}
		if(notificationData.mystore.nonRespondingSeller) {
			const notification = returnNotificationItem(notificationText.nonRespondingSeller.text, notificationText.nonRespondingSeller.link, 'red');
			userNavNotificationZoneContent.appendChild(notification);
			hasNotifications = true;
		}
		if(notificationData.mystore.ordersToShip > 0) {
			const notification = returnNotificationItem(notificationText.ordersToShip == 1 ? `${notificationData.mystore.ordersToShip} ${notificationText.ordersToShip.singular}` : `${notificationData.mystore.ordersToShip} ${notificationText.ordersToShip.plural}`, notificationText.ordersToShip.link, 'blue');
			userNavNotificationZoneContent.appendChild(notification);
			hasNotifications = true;
		}
		if(notificationData.mystore.ordersToCancel > 0) {
			const notification = returnNotificationItem(notificationText.ordersToCancel == 1 ? `${notificationData.mystore.ordersToCancel} ${notificationText.ordersToCancel.singular}` : `${notificationData.mystore.ordersToCancel} ${notificationText.ordersToCancel.plural}`, notificationText.ordersToCancel.link, 'blue');
			userNavNotificationZoneContent.appendChild(notification);
			hasNotifications = true;
		}

		if(hasNotifications) {
			userNavNotificationZone.classList.add('blp-nav-dropdown__notification-zone--active');
		}
	}
}

function returnNotificationItem(text, link, color) {
	const notification = document.createElement('a');
				notification.href = link;
				notification.classList.add('blp-nav-dropdown__notification-item', `blp-nav-dropdown__notification-item--${color}`);
	const notificationIcon = returnIconSvg(color == 'blue' ? 'circle-info-solid' : 'status-circle-exclamation-solid', 'md', ['blp-nav-dropdown__notification-icon']);
	const notificationContent = document.createElement('span');
				notificationContent.textContent = text;

	notification.appendChild(notificationIcon);
	notification.appendChild(notificationContent);

	return notification;
}

function returnUserNavContent(id) {
	let data = returnUserNavData(id);
	
	const adminUid = 'bltc40caea65dfbca5a';
	// console.log(returnIsAdmin());

	if(!returnIsAdmin()) {
		data = data.filter(item => item.uid != adminUid);
	}

	const userNavContent = document.createElement('div');

	const profileCard = returnProfileCard(id);

	userNavContent.appendChild(profileCard);
	userNavContent.appendChild(returnDivider());

	if(id == 'storeInformation') {
		const userNavNotificationZone = document.createElement('div');
					userNavNotificationZone.classList.add('blp-nav-dropdown__notification-zone');
					userNavNotificationZone.id = 'js-nav-dropdown-notification-zone';
		const notificationZoneLabel = document.createElement('h2');
					notificationZoneLabel.classList.add('blp-nav-dropdown__column-label');	
					notificationZoneLabel.textContent = 'Notifications';
		const userNavNotificationContent = document.createElement('div');
					userNavNotificationContent.classList.add('blp-nav-dropdown__notification-zone-content');
					userNavNotificationContent.id = 'js-nav-dropdown-notification-zone-content';
		
		userNavNotificationZone.appendChild(notificationZoneLabel);
		userNavNotificationZone.appendChild(userNavNotificationContent);
		userNavNotificationZone.appendChild(returnDivider());
		userNavContent.appendChild(userNavNotificationZone);
	}

	let key = 0;

	if(id == 'storeInformation' && (notificationData && notificationData.mystore.needVerification)) {
		const columnLabel = document.createElement('h2');
					columnLabel.classList.add('blp-nav-dropdown__column-label');	
					columnLabel.textContent = 'Store Management';

		//revisit when contentstack can handle this.
		userNavContent.appendChild(columnLabel);
		userNavContent.appendChild(returnColumnLink({children: 'My Store Settings', href: '/v2/mystore/display.page', icon: 'gear'}));
		userNavContent.appendChild(returnColumnLink({children: 'Seller Verification', href: '/v3/mystore/SellerVerification.page', icon: 'shield'}));
		userNavContent.appendChild(returnColumnLink({children: 'Help Center', href: '/helpMain.asp', icon: 'message-question'}));
	}else{
		data.forEach((item) => {
			if(item.label) {
				const columnLabel = document.createElement('h2');
							columnLabel.classList.add('blp-nav-dropdown__column-label');	
							columnLabel.textContent = replaceTemplateTags(item.label);	

				userNavContent.appendChild(columnLabel);
			}

			item.content.forEach((item) => {
				userNavContent.appendChild(returnColumnLink(item));
			});

			key++;

			if(key != data.length){
				userNavContent.appendChild(returnDivider());
			}
		});

		const isSeller = returnIsSeller();

		if(!isSeller) {
			userNavContent.appendChild(returnDivider());
			userNavContent.appendChild(returnColumnLink({children: 'Become a Seller', href: '/v2/register/seller_upgrade.page', icon: 'store'}));
		}
	}

	return userNavContent;
}

function returnProfileCardData(type) {
	let data;
	// console.log(notificationData);

	switch(type) {
		case 'storeInformation':
			data = returnEnv() == 'blapp' ? 
				{strUserName: blapp.session.me.store ? blapp.session.me.store.strStoreName : 'Loading...', strStoreUrl: `//store.bricklink.com/${blapp.session.me.skUsername}`, nFeedbackScore: blapp.session.me.store ? blapp.session.me.store.nFeedbackScore : '0', idProfileImg: blapp.session.me.store ? blapp.session.me.store.urlStoreLogo : '//static2.bricklink.com/img/store-fail-placeholder.png'}
			: 
				{strUserName: notificationData ? notificationData.mystore.storeName : 'Loading...', strStoreUrl: `//store.bricklink.com/${bl.session.user.user_id}`, nFeedbackScore: notificationData ? notificationData.mystore.feedbackScore : '0', idProfileImg: notificationData ? notificationData.mystore.storeLogoUrl : '//static2.bricklink.com/img/store-fail-placeholder.png'} 
			break;

		case 'profileInformation':
			data = returnEnv() == 'blapp' ? 
				{strUserName: blapp.session.me.skUsername, nFeedbackScore: blapp.session.me.nFeedbackScore, idProfileImg: blapp.session.getMyProfileURL("medium")}
			: 
				{strUserName: bl.session.user.user_id, nFeedbackScore: bl.session.user.user_fdbscore, idProfileImg: bl.session.user.user_profile_url_m} 
			break;
			
	}

	return data;
}

function returnProfileCard(type) {
	//type will be used for store/user profile
	const profileCardData = returnProfileCardData(type);

	const profileCard = document.createElement('article');
				profileCard.classList.add('blp-user-nav__profile-card');
	
	let profileCardImageContainer;

	if(type == 'storeInformation') {
		profileCardImageContainer = document.createElement('a');
		profileCardImageContainer.href = profileCardData.strStoreUrl;
	}else{
		profileCardImageContainer = document.createElement('div');
	}
	
	profileCardImageContainer.classList.add('blp-user-nav__profile-card-image-container');
	
	const profileCardImage = document.createElement('img');
				profileCardImage.classList.add('blp-user-nav__profile-card-image');
				profileCardImage.src = profileCardData.idProfileImg;
				profileCardImage.alt = `${profileCardData.strUserName} Profile Image`;

	const profileCardName = document.createElement('h1');
				profileCardName.classList.add('blp-user-nav__profile-card-name');
				profileCardName.textContent = profileCardData.strUserName;
		
	const profileCardFeedback = document.createElement('div');
				profileCardFeedback.classList.add('blp-user-nav__profile-card-feedback');
				profileCardFeedback.textContent = `${profileCardData.nFeedbackScore} feedback`;

	profileCardImageContainer.appendChild(profileCardImage);

	profileCard.appendChild(profileCardImageContainer);

	let profileCardLink;

	if(type == 'storeInformation') {
		profileCardLink = document.createElement('a');
		profileCardLink.href = profileCardData.strStoreUrl;
		profileCardLink.classList.add('blp-user-nav__profile-card-link');

		profileCardLink.appendChild(profileCardName);
		profileCard.appendChild(profileCardLink);
	}else{
		profileCard.appendChild(profileCardName);
	}

	profileCard.appendChild(profileCardFeedback);

	return profileCard;
}

function returnNavOverlay(item) {
	const navOverlay = document.createElement('div');
				navOverlay.classList.add('blp-user-nav__overlay');
				navOverlay.addEventListener('click', () => closeMobileNav(item));

	return navOverlay;
}

function closeUserNavContent(item) {
	item.dataset.state = 'closed';
	item.parentElement.querySelector('.blp-user-nav')?.remove();
}

function returnBrowseNavContent(item) {
	const data = returnUserNavData('navigationMenu');

	const userNavContainerTrack = document.createElement('div');
				userNavContainerTrack.classList.add('blp-user-nav__container-track');
	const userNavContainerPage = document.createElement('div');
				userNavContainerPage.classList.add('blp-user-nav__container-page');
				userNavContainerPage.dataset.page = 1;

	let key = 0;

	data.forEach((item) => {
		if(item.label) {
			const categoryItem = document.createElement('button');
						categoryItem.classList.add('blp-nav-dropdown__category-item');	
						categoryItem.addEventListener('click', () => {renderBrowseChildNav(item, userNavContainerTrack, item.uid, 1, 'Main'); paginateBrowseNav('down', item.uid)});
			
			const categoryContent = document.createElement('span');
						categoryContent.textContent = replaceTemplateTags(item.label);	
			const categoryIcon = returnIconSvg('chevron-right', 'large');

			categoryItem.appendChild(categoryContent);
			categoryItem.appendChild(categoryIcon);

			userNavContainerPage.appendChild(categoryItem);
		}

		key++;
	});

	userNavContainerPage.appendChild(returnDivider());
	userNavContainerPage.appendChild(returnProfileLoginButton(item));
	userNavContainerTrack.appendChild(userNavContainerPage);

	return userNavContainerTrack;
}

function renderBrowseChildNav(child, navNode, uid, page, parentLabel) {
	let newPage = page + 1;
	const userNavContainerPage = document.createElement('div');
				userNavContainerPage.classList.add('blp-user-nav__container-page');
				userNavContainerPage.dataset.page = newPage;

	const browseChildNav = document.createElement('div');
				browseChildNav.classList.add('blp-browse-child');
				browseChildNav.dataset.uid = child.uid;

				if(uid == child.uid) {
					browseChildNav.classList.add('active');
				}
	
	const userNavTopBar = document.createElement('div');
				userNavTopBar.classList.add('blp-browse-child__top-bar');
	const userNavTopBarBtn = document.createElement('button');
				userNavTopBarBtn.classList.add('blp-browse-child__top-bar-button');
				userNavTopBarBtn.addEventListener('click', () => paginateBrowseNav('up', child.uid));
	const userNavTopBarIcon = returnIconSvg('chevron-left', 'large');
	const userNavTopBarBtnText = document.createElement('span');
				userNavTopBarBtnText.textContent = replaceTemplateTags(parentLabel);
			
	const browseChildTitle = document.createElement('h2');
				browseChildTitle.classList.add('blp-browse-child__title');
				browseChildTitle.textContent = replaceTemplateTags(child.label);
	
	userNavTopBarBtn.appendChild(userNavTopBarIcon);
	userNavTopBarBtn.appendChild(userNavTopBarBtnText);
	userNavTopBar.appendChild(userNavTopBarBtn);
	browseChildNav.appendChild(userNavTopBar);
	browseChildNav.appendChild(browseChildTitle);

	let hasContent = false;

	child.content.forEach((item) => {
		if(!navNode.querySelector(`[data-uid="${uid}"]`)){
			hasContent = true;

			if(item.contentType !== 'navigation_link') {
				renderBrowseChildNav(item, navNode, item.uid, newPage, child.label);

				const categoryItem = document.createElement('button');
							categoryItem.classList.add('blp-nav-dropdown__category-item');	
							categoryItem.addEventListener('click', () => paginateBrowseNav('down', item.uid));
				
				const categoryContent = document.createElement('span');
							categoryContent.textContent = replaceTemplateTags(item.label);	
				const categoryIcon = returnIconSvg('chevron-right', 'large');

				categoryItem.appendChild(categoryContent);
				categoryItem.appendChild(categoryIcon);

				browseChildNav.appendChild(categoryItem);
			}else{
				const categoryItem = document.createElement('a');
							categoryItem.classList.add('blp-nav-dropdown__category-item');	
							categoryItem.href = replaceTemplateTags(item.href);
				
				const categoryContent = document.createElement('span');
							categoryContent.textContent = replaceTemplateTags(item.children);	

				if(item.newTab) {
					categoryItem.target = '_blank';
			
					const externalLinkIcon = returnIconSvg('arrow-up-right-from-square', 'sm');
					categoryContent.appendChild(externalLinkIcon);
				}
				
				categoryItem.appendChild(categoryContent);

				browseChildNav.appendChild(categoryItem);
			}
		}
	});

	if(hasContent) {
		userNavContainerPage.appendChild(browseChildNav);
		navNode.appendChild(userNavContainerPage);
	}
}

function paginateBrowseNav(direction, uid) {
	const browseNav = document.querySelector('.blp-user-nav__container');

	const currentPage = parseInt(browseNav.dataset.page ? browseNav.dataset.page : 1);

	switch(direction) {
		case 'down':
			browseNav.dataset.page = parseInt(currentPage) + 1;
			
			browseNav.querySelectorAll(`.blp-user-nav__container-page[data-page="${currentPage + 1}"]`).forEach((item) => {
				item.querySelector('.blp-browse-child').classList.remove('active');
			});
			browseNav.querySelector(`[data-uid="${uid}"]`).classList.add('active');
			
			break;

		case 'up':
			browseNav.dataset.page = parseInt(browseNav.dataset.page) - 1;

			break;
	}
}

function returnProfileLoginButton(item) {
	const isLoggedIn = returnIsLoggedIn();

	if(isLoggedIn) {
		const profileImage = returnEnv() == 'blapp' ? blapp.session.getMyProfileURL("medium") : bl.session.user.user_profile_url_m;

		const profileLoginButton = document.createElement('button');
					profileLoginButton.classList.add('blp-nav-dropdown__category-item', 'blp-nav-dropdown__category-item--profile');
					profileLoginButton.addEventListener('click', () => paginateToProfile(item));
		const profileLoginButtonContentContainer = document.createElement('div');
					profileLoginButtonContentContainer.classList.add('blp-nav-dropdown__profile-link-container');
		const profileLoginButtonProfileImageContainer = document.createElement('div');
					profileLoginButtonProfileImageContainer.classList.add('blp-nav-dropdown__profile-link-image-container');
		const profileLoginButtonProfileImage = document.createElement('img');
					profileLoginButtonProfileImage.classList.add('blp-nav-dropdown__profile-link-image');
					profileLoginButtonProfileImage.src = profileImage;
					profileLoginButtonProfileImage.alt = 'Profile Image';
		const profileLoginButtonContent = document.createElement('span');
					profileLoginButtonContent.textContent = 'Profile';	
		const profileLoginButtonNotification = document.createElement('span');
					profileLoginButtonNotification.classList.add('blp-nav-dropdown__column-link-notification');
					profileLoginButtonNotification.id = 'js-notification-profile-mobile';
		const profileLoginButtonIcon = returnIconSvg('chevron-right', 'large');

					profileLoginButtonProfileImageContainer.appendChild(profileLoginButtonProfileImage);
					profileLoginButtonContentContainer.appendChild(profileLoginButtonProfileImageContainer);
					profileLoginButtonContentContainer.appendChild(profileLoginButtonContent);
					profileLoginButtonContentContainer.appendChild(profileLoginButtonNotification);
					profileLoginButton.appendChild(profileLoginButtonContentContainer);
					profileLoginButton.appendChild(profileLoginButtonIcon);

		return profileLoginButton;
	}else{
		const profileLoginButton = document.createElement('button');
					profileLoginButton.classList.add('blp-nav-dropdown__category-item', 'blp-nav-dropdown__category-item--login');
					profileLoginButton.textContent = 'Sign In';
					profileLoginButton.addEventListener('click', () => loginLogout('login'));

		return profileLoginButton;
	}
}

function paginateToProfile() {
	const profileContent = returnUserNavContent('profileInformation');

	const userNavContainerTrack = document.querySelector('.blp-user-nav__container-track');

	const userNavContainerPage = document.createElement('div');
				userNavContainerPage.classList.add('blp-user-nav__container-page');
				userNavContainerPage.dataset.page = 2;

	const browseChildNav = document.createElement('div');
				browseChildNav.classList.add('blp-browse-child');
				browseChildNav.dataset.uid = 'profileInformation';

	const userNavTopBar = document.createElement('div');
				userNavTopBar.classList.add('blp-browse-child__top-bar');
	const userNavTopBarBtn = document.createElement('button');
				userNavTopBarBtn.classList.add('blp-browse-child__top-bar-button');
				userNavTopBarBtn.addEventListener('click', () => paginateBrowseNav('up', 'profileInformation'));
	const userNavTopBarIcon = returnIconSvg('chevron-left', 'large');
	const userNavTopBarBtnText = document.createElement('span');
				userNavTopBarBtnText.textContent = 'Main';
			
	const browseChildTitle = document.createElement('h2');
				browseChildTitle.classList.add('blp-browse-child__title');
				browseChildTitle.textContent = 'Profile';
	
	userNavTopBarBtn.appendChild(userNavTopBarIcon);
	userNavTopBarBtn.appendChild(userNavTopBarBtnText);
	userNavTopBar.appendChild(userNavTopBarBtn);
	browseChildNav.appendChild(userNavTopBar);
	browseChildNav.appendChild(browseChildTitle);
	browseChildNav.appendChild(profileContent);

	userNavContainerPage.appendChild(browseChildNav);
	userNavContainerTrack.appendChild(userNavContainerPage);

	paginateBrowseNav('down', 'profileInformation');
	handleUserNavActions();
	handleUserNavNotifications();
}

async function loginLogout(type) {
	if(type == 'login') {
		bl.login.showLoginModal('login');
	}else{
		setCookie('blpSearchType', '', 0);
		bl.login.logoutAndReload();
		// console.log('logout');
	}
}

async function rebuildIconNav() {
	await initPersonalization();

	const iconNav = document.getElementById('js-blp-icon-nav');

	//replace nav if callback login succesful
	iconNav.replaceWith(returnIconNav());
	handleIconNav();
	handleIconNavNotifications();
}

async function rebuildCartNav() {
	await initCartCount();

	const cartIcon = document.getElementById('js-notification-cart');
	cartIcon.innerHTML = '';

	if(cartCount === 0) {
		cartDataCache = null;
		cartIcon.classList.add('blp-icon-nav__item-notification--hidden');
	}else{
		// fetchCartData();
		cartIcon.classList.remove('blp-icon-nav__item-notification--hidden');
		cartIcon.textContent = cartCount;
	}
}

function returnUserCartContainer() {
	const cartContentContainer = document.createElement('div');
				cartContentContainer.classList.add('blp-cart-content-container');

	return cartContentContainer;
}

async function renderCartContent() {
	const container = document.querySelector('.blp-cart-content-container');

	if(cartDataPromise == null) {
		container.appendChild(renderCartHeader());
		container.appendChild(renderCartFooter());
	
		try{
			await fetchCartData();
		}finally{
			container.innerHTML = '';
			container.appendChild(renderCartHeader());
			if(cartDataCache) {
				container.appendChild(renderCartList());
			}
			container.appendChild(renderCartFooter());
		}
	}else{
		container.appendChild(renderCartHeader());

		if(cartDataCache) {
			container.appendChild(renderCartList());
		}
		container.appendChild(renderCartFooter());
	}
}

function renderCartHeader() {
	const headerContainer = document.createElement('div');
				headerContainer.classList.add('blp-header-container');

	if(!cartDataCache || cartCount === 0) {
		headerContainer.classList.add('blp-header-container--no-carts');
	}
  
	const heading = document.createElement('h3');
				heading.classList.add('blp-cart__heading');
				heading.textContent = 'My Carts';
				headerContainer.appendChild(heading);
  
	if(cartDataPromise == null) {
	  const loadingContainer = document.createElement('div');
	  			loadingContainer.classList.add('blp-cart__loading-container');
  
	  const spinner = document.createElement('div');
	  			spinner.classList.add('blp-cart__spinner');
  
	  const loaderIcon = returnIconSvg('loader', 'md');

	  spinner.appendChild(loaderIcon);
  
	  const loadingText = document.createElement('span');
					loadingText.classList.add('text-bold');
					loadingText.textContent = 'Loading...';
  
	  loadingContainer.appendChild(spinner);
	  loadingContainer.appendChild(loadingText);
	  headerContainer.appendChild(loadingContainer);
	}
  
	if(cartError) {
	  const errorContainer = document.createElement('div');
	  			errorContainer.classList.add('blp-cart-error');
  
	  const errorIcon = returnIconSvg('status-triangle-exclamation-solid', 'md');
	 				errorIcon.classList.add('blp-cart-error__icon');

	  errorContainer.appendChild(errorIcon);
  
	  const errorTextContainer = document.createElement('div');
	 				errorTextContainer.classList.add('blp-cart-error__text');
  
	  const errorMessageBold = document.createElement('span');
	  			errorMessageBold.classList.add('text-bold');
	  			errorMessageBold.textContent = 'Cart data cannot be retrieved.';

	  errorTextContainer.appendChild(errorMessageBold);
  
	  const errorMessage = document.createElement('span');
	 				errorMessage.textContent = 'Try refreshing your page.';

	  errorTextContainer.appendChild(errorMessage);
  
	  const errorHelpText = document.createElement('span');
	  			errorHelpText.innerHTML = 'If the issue persists, please contact our <a href="https://www.bricklink.com/helpDesk.asp" class="link-inline link-emphasis">Help Desk</a>.';
	 
		errorTextContainer.appendChild(errorHelpText);
	  errorContainer.appendChild(errorTextContainer);
	  headerContainer.appendChild(errorContainer);
	}
  
	if(cartDataCache || cartCount === 0) {
	  const headerContent = document.createElement('div');
	  			headerContent.classList.add('blp-cart-header');
		
		const headerLoading = headerContainer.querySelectorAll('.blp-cart__loading-container')[0];
					headerLoading.remove();
  
	  if(cartCount === 0) {
			const noCartsText = document.createElement('p');
						noCartsText.classList.add('text-lg', 'text-bold');
						noCartsText.textContent = 'You have no open carts.';

			headerContent.appendChild(noCartsText);
  
			const discoverText = document.createElement('p');
						discoverText.textContent = 'Discover rare vintage sets, find lost parts, or search for a specific item!';
			headerContent.appendChild(discoverText);
	  }else{
			const {cartCount, lotCount, subtotalPrice, currencyCode} = cartDataCache.cartSummary;
			const storeInfo = document.createElement('div');
		
			const storeCountText = document.createElement('p');
						storeCountText.classList.add('text-md');
						storeCountText.textContent = `${cartCount} store${cartCount === 1 ? '' : 's'}, ${lotCount} lot${lotCount === 1 ? '' : 's'}`;
			
			storeInfo.appendChild(storeCountText);
		
			const subtotalText = document.createElement('p');
						subtotalText.classList.add('text-md');
						subtotalText.textContent = `Subtotal: ${formatMoney(subtotalPrice, currencyCode)}`;
			
			storeInfo.appendChild(subtotalText);
		
			headerContent.appendChild(storeInfo);
		
			const shippingText = document.createElement('p');
						shippingText.classList.add('text-sm');
						shippingText.textContent = 'S&H and taxes will be calculated at checkout.';

			headerContent.appendChild(shippingText);
			headerContent.appendChild(returnDivider(['blp-cart-header__divider']));
	  }
  
	  headerContainer.appendChild(headerContent);
	}
  
	return headerContainer;
}

function formatMoney(amount, currencyCode) {
	// Default to "en-us" locale for now
	return Intl.NumberFormat("en-us", {
		style: "currency",
		currency: currencyCode,
		currencyDisplay: "code",
	}).format(amount);
}

function returnFlagIcon(countryCode) {
	const iconSvg = document.createElement('div');
				iconSvg.classList.add('blp-flag-icon');
				iconSvg.setAttribute('aria-hidden', 'true');

	const url = `https://static2.bricklink.com/img/flags/${countryCode.toLowerCase()}.svg`;

	checkUrlExists(url).then(exists => {
		if(exists) {
			iconSvg.style.setProperty('background-image', `url('${url}')`);
		}else{
			iconSvg.style.setProperty('background-image', `url('${returnPlaceholderFlag()}')`);
		}
	});

	return iconSvg;
}

function returnFeedbackBrick(feedbackScore) {
	const feedbackLevel = getLevelFromFeedbackScore(feedbackScore);
	let	idx;

	if(feedbackLevel === 10) {
		idx = '010';
	}else{
		idx = `00${feedbackLevel}`;
	}

	const iconSvg = document.createElement('div');
				iconSvg.classList.add('blp-feedback-brick-icon');
				iconSvg.setAttribute('aria-hidden', 'true');

	const url = `https://static2.bricklink.com/img/feedback-bricks/feedback_${idx}.svg`;
	iconSvg.style.setProperty('background-image', `url('${url}')`);

	return iconSvg;
}	

function getLevelFromFeedbackScore(feedbackScore) {
	const thresholds = [10, 50, 100, 500, 1000, 2500, 5000, 10000, 25000, 50000];
    
	for(let i = 0; i < thresholds.length; i++) {
		if(feedbackScore < thresholds[i]) {
			return i;
		}
	}
	
	return 10;
}

async function checkUrlExists(url) {
	try{
		const response = await fetch(url, {method: 'HEAD'});
		return response.ok;
	}catch (error){
		return false;
	}
}

function returnPlaceholderFlag() {
	// From portal's generatePlaceholderImage function
	return "data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228%22%20height%3D%2220%22%20viewBox%3D%220%200%2028%2020%22%3E%20%3Crect%20fill%3D%22%23e4e5ec%22%20width%3D%2228%22%20height%3D%2220%22%20rx%3D%222%22%20ry%3D%222%22%20%2F%3E%20%3Ctext%20fill%3D%22%239192a1%22%20font-family%3D%22system-ui%2C%20-apple-system%2C%20BlinkMacSystemFont%2C%20%5CiSegoe%20UI%5Ci%2C%20Roboto%2C%20Oxygen%2C%20Ubuntu%2C%20Cantarell%2C%20%5CiOpen%20Sans%5Ci%2C%20%5CiHelvetica%20Neue%5Ci%2C%20sans-serif%22%20font-size%3D%2212%22%20dy%3D%220.35em%22%20x%3D%2250%25%22%20y%3D%2250%25%22%20text-anchor%3D%22middle%22%3E%E2%9A%A0%EF%B8%8F%3C%2Ftext%3E%20%3C%2Fsvg%3E";
}

function clamp(value, min, max) {
	return Math.min(Math.max(value, min), max);
}

function getHost(host) {
	if(window.hasOwnProperty("blapp")) {
		return blapp.config.getHost(host);
	}else if(window.hasOwnProperty("bl")) {
		return bl.util.getBLHost(host);
	}

	return host + ".bricklink.com";
}


function getStoreCartUrl(storeUsername) {
	return "//" + getHost( "store" ) + "/" + storeUsername + "#/cart";
}

function renderStoreLockup(storeData) {
	const {storeName, countryCode, orderCount, supportsInstantCheckout, feedbackScore, praiseRate} = storeData;
	const clampedPraiseRate = praiseRate !== undefined ? clamp(praiseRate, 0, 100) : undefined;
	const clampedOrderCount = clamp(orderCount, 0, Number.POSITIVE_INFINITY);
  
	const containerDiv = document.createElement('div');
				containerDiv.classList.add('blp-store-lockup');
  
	const storeNameContainer = document.createElement('div');
				storeNameContainer.classList.add('blp-store-lockup__store-name-container');
  
	const flagIcon = returnFlagIcon(countryCode);
  
	const storeNameText = document.createElement('span');
				storeNameText.classList.add('blp-store-lockup__store-name-text');
				storeNameText.textContent = storeName;
  
	const feedbackBrick = document.createElement('div');
				feedbackBrick.classList.add('blp-store-lockup__feedback-brick');
  
	storeNameContainer.appendChild(flagIcon);
	storeNameContainer.appendChild(storeNameText);
	storeNameContainer.appendChild(returnFeedbackBrick(feedbackScore));
  
	if(supportsInstantCheckout) {
	  const instantCheckoutIcon = returnIconSvg('bolt-solid', 'md', ['blp-instant-checkout-icon']);
		
		storeNameContainer.appendChild(instantCheckoutIcon);
	}
  
	const storeFeedbackContainer = document.createElement('div');
				storeFeedbackContainer.classList.add('blp-store-feedback');
  
	const praiseRateText = document.createElement('span');
				praiseRateText.classList.add('text-sm');
				praiseRateText.textContent = clampedPraiseRate === undefined ? '-- praise' : `${clampedPraiseRate === 100 ? clampedPraiseRate : clampedPraiseRate.toFixed(1)}% praise`;
  
	const feedbackDivider = document.createElement('div');
				feedbackDivider.classList.add('blp-store-feedback__divider');
				feedbackDivider.setAttribute('data-orientation', 'vertical');
				feedbackDivider.setAttribute('data-decorative', 'true');
  
	const orderCountText = document.createElement('span');
				orderCountText.classList.add('text-sm');
				orderCountText.textContent = `${clampedOrderCount.toLocaleString()} order${clampedOrderCount === 1 ? '' : 's'}`;
  
	storeFeedbackContainer.appendChild(praiseRateText);
	storeFeedbackContainer.appendChild(feedbackDivider);
	storeFeedbackContainer.appendChild(orderCountText);
  
	containerDiv.appendChild(storeNameContainer);
	containerDiv.appendChild(storeFeedbackContainer);
  
	return containerDiv;
}

function renderCartLink(cartData, currencyCode) {
	const {subtotalPrice, lotCount, shippingPrice, storeUsername, isEstimatedShipping} = cartData;
  
	const link = document.createElement('a');
				link.classList.add('blp-cart-item');
				link.href = getStoreCartUrl(storeUsername);
  
	const containerDiv = document.createElement('div');
				containerDiv.classList.add('blp-cart-item__information');
				containerDiv.appendChild(renderStoreLockup(cartData));
  
	const subtotalText = document.createElement('p');
				subtotalText.textContent = `Subtotal: ${formatMoney(subtotalPrice, currencyCode)} (${lotCount} lot${lotCount === 1 ? '' : 's'})`;
	
	containerDiv.appendChild(subtotalText);

	const shippingText = document.createElement('p');
				shippingText.textContent = `${isEstimatedShipping ? 'Estimated' : ''} S&H: ${shippingPrice === null ? 'TBD' : `${formatMoney(shippingPrice, currencyCode)}`}`;

	containerDiv.appendChild(shippingText);
  
	link.appendChild(containerDiv);
	link.appendChild(returnIconSvg('chevron-right', 'large', ['blp-cart-item__link-icon']));
  
	return link;
}

function renderCartList() {
	const {carts} = cartDataCache;
	const {currencyCode} = cartDataCache.cartSummary;

	const cartListContainer = document.createElement('div');
				cartListContainer.classList.add('blp-cart-list');

	carts.forEach((cart, idx) => {
		if(idx !== 0) {
			cartListContainer.appendChild(returnDivider(['blp-cart-list__divider']));
		}

		cartListContainer.appendChild(renderCartLink(cart, currencyCode));
	});

	return cartListContainer;
}

function renderCartFooter() {
	const footerContainer = document.createElement('div');
				footerContainer.classList.add('blp-cart-footer');
  
	const button = document.createElement('div');
				button.classList.add('blp-button');
  
	if(cartDataPromise == null || cartError) {
	  const buttonLink = document.createElement('a');
	  			buttonLink.href = 'https://www.bricklink.com/v2/globalcart.page';
	  			buttonLink.textContent = 'View All Carts';

	  button.appendChild(buttonLink);
	}else if(cartCount === 0){
		const buttonLink = document.createElement('a');
					buttonLink.href = 'https://www.bricklink.com/catalog.asp?utm_content=subnav';
					buttonLink.textContent = 'Browse Catalog Items';

		button.appendChild(buttonLink);
	}else if(cartDataCache){
		const buttonLink = document.createElement('a');
					buttonLink.href = 'https://www.bricklink.com/v2/globalcart.page';
					buttonLink.textContent = 'View All Carts';

		button.appendChild(buttonLink);
	}
	footerContainer.appendChild(button);

	return footerContainer;
}

function renderSearchNavContainer(item, content) {
	const itemParent = item.parentElement;

	const userNavMenu = document.createElement('div');
				userNavMenu.classList.add('blp-user-nav', 'blp-user-nav--search');
				setTimeout(() => {
					userNavMenu.classList.add('blp-user-nav--opening');
				}, 100);
	const userNavContainer = document.createElement('div');
				userNavContainer.classList.add('blp-user-nav__container');
				userNavContainer.dataset.page = 1;
	
	const userNavTopBar = document.createElement('div');
				userNavTopBar.classList.add('blp-user-nav__top-bar');

	const userNavBack = document.createElement('button');
				userNavBack.classList.add('blp-user-nav__back');
				userNavBack.addEventListener('click', () => closeMobileNav(item));
	const userNavBackIcon = returnIconSvg('chevron-left', 'large');

	const navOverlay = returnNavOverlay(item);

	const searchResultsContainer = document.createElement('div');
				searchResultsContainer.id = 'blp-mobile-search-results';
				searchResultsContainer.classList.add('blp-mobile-search-results');
	const searchResultsInternal = document.createElement('div');
				searchResultsInternal.id = 'resultContainer';
				searchResultsInternal.classList.add('blp-mobile-search-results__container');

	searchResultsContainer.appendChild(searchResultsInternal);
	
	userNavBack.appendChild(userNavBackIcon);
	userNavTopBar.appendChild(userNavBack);
	userNavContainer.appendChild(userNavTopBar);
	userNavContainer.appendChild(returnAdvancedSearchBar('mobile'));
	userNavContainer.appendChild(searchResultsContainer);
	userNavMenu.appendChild(navOverlay);
	userNavMenu.appendChild(userNavContainer);
	itemParent.appendChild(userNavMenu);

	const searchSelector = document.querySelector('.blp-adv-search__wrapper--mobile .blp-adv-search__selector');

	document.addEventListener('click', (e) => {
		if(searchSelector.dataset.state == 'open') {
			if(!searchSelector.contains(e.target)) {
				searchSelector.dataset.state = 'closed';
			}
		}
	});

	blSearchAutocomplete().attachToElem( 
		document.querySelector('.blp-adv-search__wrapper--mobile .blp-adv-search__input')
	,	'mobile'
	, function(q) {
			doSearch(q, document.querySelector('.blp-adv-search__wrapper--mobile .blp-adv-search__form'));
		}.bind(this)
	);
}


function returnSubNavData(label) {
	const data = returnMainNavData();
	const subNavData = data['navigationMenu'][data['navigationMenu'].findIndex(item => item.label.toLowerCase() === label.toLowerCase())];

	return subNavData;
}

function returnUserNavData(id) {
	const data = returnMainNavData();
	const iconNavData = data[id];

	return iconNavData;
}

const searchSelectorItems = [
		{label: 'All Items', type: 'p', advanced: '//www.bricklink.com/searchAdvanced.asp', reqLoggedIn: false}
	,	{label: 'Catalog Items', type: 'c', advanced: '//www.bricklink.com/catalogSearch.asp', reqLoggedIn: false}
	, {label: 'Store Items', type: 'i', advanced: '//www.bricklink.com/searchAdvanced.asp', reqLoggedIn: false}
	, {label: 'Store Name', type: 's', advanced: false, reqLoggedIn: false}
	, {label: 'Forum', type: 'm', advanced: '//www.bricklink.com/messageSearch.asp', reqLoggedIn: false}
	, {label: 'My Store Inventory', type: 'n', advanced: '//www.bricklink.com/messageSearch.asp', reqLoggedIn: true}
	, {label: 'Wanted List', type: 'w', advanced: '//www.bricklink.com/messageSearch.asp', reqLoggedIn: true}
]

function returnAdvancedSearchBar(type = 'desktop') {
	const savedSearchType = getCookie('blpSearchType');

	const searchWrapper = document.createElement('div');
				searchWrapper.classList.add('blp-adv-search__wrapper', `blp-adv-search__wrapper--${type}`);
	const searchSelector = document.createElement('div');
				searchSelector.classList.add('blp-adv-search__selector');
				searchSelector.dataset.state = 'closed';
	const searchSelectorTrigger = document.createElement('button');
				searchSelectorTrigger.classList.add('blp-adv-search__selector-trigger');
				searchSelectorTrigger.addEventListener('click', () => toggleSearchSelector(searchWrapper));
	const searchSelectorTriggerText = document.createElement('span');
				searchSelectorTriggerText.classList.add('blp-adv-search__selector-trigger-text');
				searchSelectorTriggerText.textContent = savedSearchType ? searchSelectorItems.find(item => item.type == savedSearchType).label : searchSelectorItems[0].label;
	const searchSelectorOptions = document.createElement('ul');
				searchSelectorOptions.classList.add('blp-adv-search__selector-options');

	searchSelectorItems.filter(item => !returnIsLoggedIn() ? item.reqLoggedIn == false : true).forEach((item, key) => {
		const searchSelectorOptionsItem = document.createElement('li');
					searchSelectorOptionsItem.classList.add('blp-adv-search__selector-item');

		if(getCookie('blpSearchType')) {
			if(getCookie('blpSearchType') == item.type) {
				searchSelectorOptionsItem.classList.add('blp-adv-search__selector-item--active');
			}
		}else{
			if(key == 0) {
				searchSelectorOptionsItem.classList.add('blp-adv-search__selector-item--active');
			}
		}

		const searchSelectorOptionsItemBtn = document.createElement('button');
					searchSelectorOptionsItemBtn.classList.add('blp-adv-search__selector-item-btn');
					searchSelectorOptionsItemBtn.textContent = item.label;
					searchSelectorOptionsItemBtn.dataset.type = item.type;
					searchSelectorOptionsItemBtn.addEventListener('click', () => {
						selectSearchType(item, searchWrapper);
					});

		if(returnIsLoggedIn() && key == 5) {
			searchSelectorOptions.appendChild(returnSelectorOption('Advanced Search', '/searchAdvanced.asp', ['blp-adv-search__selector-item--advanced']));
		}

		searchSelectorOptionsItem.appendChild(searchSelectorOptionsItemBtn);
		searchSelectorOptions.appendChild(searchSelectorOptionsItem);
	});

	if(!returnIsLoggedIn()) {
		searchSelectorOptions.appendChild(returnSelectorOption('Advanced Search', '/searchAdvanced.asp', ['blp-adv-search__selector-item--advanced']));
	}

	searchSelectorTrigger.appendChild(searchSelectorTriggerText);
	searchSelector.appendChild(searchSelectorTrigger);
	searchSelector.appendChild(searchSelectorOptions);
	searchWrapper.appendChild(searchSelector);

	const searchForm = document.createElement('form');
				searchForm.classList.add('blp-adv-search__form');
				searchForm.method = 'POST';
				searchForm.action = '/searchRedirect.asp';
				searchForm.dataset.type = searchSelectorItems[0].type;
	
	const searchInput = document.createElement('input');
				searchInput.classList.add('blp-adv-search__input');
				searchInput.type = 'search';
				searchInput.placeholder = 'Search...';
				searchInput.name = 'q';
				searchInput.setAttribute('aria-label', 'Search');

	const searchInputHidden = document.createElement('input');
				searchInputHidden.classList.add('bl-adv-search__type-input');
				searchInputHidden.type = 'hidden';
				searchInputHidden.name = 'searchType';
				searchInputHidden.value = searchSelectorItems[0].type;

	if(getCookie('blpSearchType')) {
		searchInputHidden.value = getCookie('blpSearchType');
	}else{
		searchInputHidden.value = searchSelectorItems[0].type;
	}

	const searchSubmit = document.createElement('button');
				searchSubmit.classList.add('blp-btn', 'blp-adv-search__submit');
				searchSubmit.type = 'submit';
	const searchSubmitIcon = returnIconSvg('magnifying-glass', 'sm', ['blp-icon--search']);

	searchSubmit.appendChild(searchSubmitIcon);
	searchForm.appendChild(searchInput);
	searchForm.appendChild(searchSubmit);
	searchForm.appendChild(searchInputHidden);

	searchWrapper.appendChild(searchForm);

	return searchWrapper;
}

function returnSelectorOption(text, link, classes = []) {
	const searchSelectorOption = document.createElement('li');
				searchSelectorOption.classList.add('blp-adv-search__selector-item', ...classes);
	const searchSelectorOptionBtn = document.createElement('a');
				searchSelectorOptionBtn.classList.add('blp-adv-search__selector-item-btn');
				searchSelectorOptionBtn.href = link;
				searchSelectorOptionBtn.textContent = text;

	searchSelectorOption.appendChild(searchSelectorOptionBtn);

	return searchSelectorOption;
}

function rebuildSearchDropdown() {
	const searchContainerDesktop = document.getElementById('js-blp-search');
				searchContainerDesktop.innerHTML = '';
				searchContainerDesktop.appendChild(returnAdvancedSearchBar());
}

function toggleSearchSelector(parent) {
	const searchSelector = parent.querySelector('.blp-adv-search__selector');

	if(searchSelector.dataset.state == 'open') {
		searchSelector.dataset.state = 'closed';
	}else{
		searchSelector.dataset.state = 'open';
	}
}

function selectSearchType(item, parent) {
	const searchSelectorTriggerText = parent.querySelector('.blp-adv-search__selector-trigger-text');
	const searchForm = parent.querySelector('.blp-adv-search__form');
				searchForm.dataset.type = item.type;
	const searchInputHidden = searchForm.querySelector('.bl-adv-search__type-input');
				searchInputHidden.value = item.type;
	const searchSelectorOptions = parent.querySelectorAll('.blp-adv-search__selector-item-btn');
	const selectedItem = parent.querySelector(`.blp-adv-search__selector-item-btn[data-type="${item.type}"]`);
				selectedItem.parentNode.classList.add('blp-adv-search__selector-item--active');

	searchSelectorOptions.forEach((item) => {
		if(item != selectedItem) {
			item.parentNode.classList.remove('blp-adv-search__selector-item--active');
		}
	});

	searchSelectorTriggerText.textContent = item.label;

	parent.querySelector('.blp-adv-search__input').focus();

	//set cookie
	setCookie('blpSearchType', item.type, 1);
	
	toggleSearchSelector(parent);
}

function handleSearch() {
	const searchForm = document.querySelector('.blp-adv-search__form');
	const searchSelector = document.querySelector('.blp-adv-search__wrapper--desktop .blp-adv-search__selector');

	document.addEventListener('click', (e) => {
		if(searchSelector.dataset.state == 'open') {
			if(!searchSelector.contains(e.target)) {
				searchSelector.dataset.state = 'closed';
			}
		}
	});

	blSearchAutocomplete().attachToElem( 
		searchForm.querySelector('.blp-adv-search__wrapper--desktop .blp-adv-search__input')
	,	'desktop'
	,	function(q) {
			doSearch(q, document.querySelector('.blp-adv-search__wrapper--desktop .blp-adv-search__form'));
		}.bind(this)
	);
}

function doSearch(q, searchForm) {
	searchForm.querySelector('.blp-adv-search__input').value = q;
	searchForm.submit();
}

function returnMainNavItem(item) {
	const mainNavItem = document.createElement('li');
				mainNavItem.classList.add('blp-nav__main-item');
	const mainNavBtn = document.createElement('button');
				mainNavBtn.classList.add('blp-btn', 'blp-nav__main-btn');
				mainNavBtn.id = `js-trigger-${item.label.toLowerCase().replace(/ /g, '-')}`;
				mainNavBtn.setAttribute('aria-current', 'false');
				mainNavBtn.setAttribute('data-state', 'closed');
				mainNavBtn.setAttribute('aria-expanded', 'false');
				mainNavBtn.setAttribute('aria-controls', `content-${item.label.toLowerCase().replace(/ /g, '-')}`);
	const mainNavBtnText = document.createElement('span');
				mainNavBtnText.textContent = replaceTemplateTags(item.label);
	const mainNavBtnChevron = returnAnimatedChevron(['blp-chevron']);

	mainNavBtn.appendChild(mainNavBtnText);
	mainNavBtn.appendChild(mainNavBtnChevron);
	mainNavItem.appendChild(mainNavBtn);

	return mainNavItem;
}

function returnAnimatedChevron(classes = '') {
	const chevron = document.createElement('div');
				chevron.classList.add('chevron', ...classes);
	const chevronLeft = document.createElement('div');
				chevronLeft.classList.add('chevron__line', 'chevron__left');
	const chevronRight = document.createElement('div');
				chevronRight.classList.add('chevron__line', 'chevron__right');

	chevron.appendChild(chevronLeft);
	chevron.appendChild(chevronRight);

	return chevron;
}

function returnEnv() {
	return typeof blapp !== 'undefined' ? 'blapp' : 'bl';
}

function returnIsLoggedIn() {
	const isLoggedIn = returnEnv() == 'blapp' ? blapp.session.isLoggedIn() : bl.session.is_loggedin;

	return isLoggedIn;
}

function returnIsSeller() {
	if(returnIsLoggedIn()) {
		const isSeller = notificationData && notificationData.nUserType > 1;

		return isSeller;
	}else{
		return false;
	}
}

function returnIsAdmin() {
	if(returnIsLoggedIn()) {
		const isAdmin = notificationData ? notificationData.nUserType > 2 : false;

		return isAdmin;
	}else{
		return false;
	}
}

// function decodeHTML(txt) {
// 	return String(this.unescapeUnicode(txt)).replace(/&quot;/g, '"').replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&amp;/g, '&').replace(/&#([\d\w]+);/gi, function (match, grp) {return String.fromCharCode(parseInt(grp, 10))});
// }


function renderFooter() {
	const blpFooter = document.getElementById('js-blp-footer');
	const footer = document.createElement('footer');
				footer.classList.add('blp-footer');
	
	const footerContent = document.createElement('div');
				footerContent.classList.add('blp-footer__content');
	
	const footerBrickLinkColumn = document.createElement('section');
				footerBrickLinkColumn.classList.add('blp-footer-column', 'blp-footer-column--bricklink');
				footerBrickLinkColumn.appendChild(returnFooterColumnContent(footerData.bricklink));
	
	const footerResourcesColumn = document.createElement('section');
				footerResourcesColumn.classList.add('blp-footer-column', 'blp-footer-column--resources');
				footerResourcesColumn.appendChild(returnFooterColumnContent(footerData.resources));
	
	const footerJoinColumn = document.createElement('section');
				footerJoinColumn.classList.add('blp-footer-column', 'blp-footer-column--join');
				footerJoinColumn.appendChild(returnFooterColumnContent(footerData.joinIn));

	const footerFollowUs = document.createElement('section');
				footerFollowUs.classList.add('blp-footer-column', 'blp-footer-column--follow-us');
				footerFollowUs.appendChild(returnFooterColumnContent(footerData.followUs, false));

	const footerPopularPaymentOptions = document.createElement('section');
				footerPopularPaymentOptions.classList.add('blp-footer-column', 'blp-footer-column--payment');
				footerPopularPaymentOptions.appendChild(returnFooterPaymentContent(footerData.bricklink));
	
	const footerMeta = document.createElement('section');
				footerMeta.classList.add('blp-footer-column', 'blp-footer-column--meta');
				footerMeta.appendChild(returnFooterColumnContent(footerData.meta, false));

	const footerDisclaimer = document.createElement('section');
				footerDisclaimer.classList.add('blp-footer-column', 'blp-footer-column--disclaimer');
	const footerDisclaimerContent = document.createElement('p');
				footerDisclaimerContent.classList.add('blp-footer__disclaimer');
				footerDisclaimerContent.innerHTML = footerData.disclaimer;

	footerDisclaimer.appendChild(footerDisclaimerContent);

	footerContent.appendChild(footerBrickLinkColumn);
	footerContent.appendChild(footerResourcesColumn);
	footerContent.appendChild(footerJoinColumn);
	footerContent.appendChild(footerFollowUs);
	footerContent.appendChild(footerPopularPaymentOptions);
	footerContent.appendChild(footerMeta);
	footerContent.appendChild(footerDisclaimer);

	footer.appendChild(footerContent);
	blpFooter.appendChild(footer);
}


function returnFooterColumnContent(column, isDrawer = true) {
	const columnContent = document.createElement('div');
				columnContent.classList.add('blp-footer-column__content');

	if(column[0].label) {
		const columnTitle = document.createElement('h2');
					columnTitle.classList.add('blp-footer-column__title');

		if(isDrawer) {
			const columnButton = document.createElement('button');
						columnButton.classList.add('blp-footer-column__toggle');
						columnButton.textContent = replaceTemplateTags(column[0].label);
						columnButton.addEventListener('click', () => toggleFooterColumnContent(columnContent));
			const columnButtonIcon = returnAnimatedChevron(['blp-chevron--footer', 'blp-footer-column__toggle-chevron']);
			
			columnButton.appendChild(columnButtonIcon);
			columnTitle.appendChild(columnButton);
		}else{
			columnTitle.textContent = replaceTemplateTags(column[0].label);
		}
		
		columnContent.appendChild(columnTitle);
	}
	
	const columnListContainer = document.createElement('div');
				columnListContainer.classList.add('blp-footer-column__list-container');

	if(isDrawer) {
		columnListContainer.setAttribute('data-state', 'closed');
	}

	const columnList = document.createElement('ul');
				columnList.classList.add('blp-footer-column__list');

	column[0].content.forEach((item) => {
		let columnLinkText, columnLinkIcon;
		const columnLinkContainer = document.createElement('li');
					columnLinkContainer.classList.add('blp-footer-column__item');

		const columnLink = document.createElement('a');
					columnLink.classList.add('blp-footer-column__link');
					columnLink.href = replaceTemplateTags(item.href);

		if(item.icon) {
			columnLinkIcon = returnIconSvg(item.icon, 'md');
			
			columnLink.appendChild(columnLinkIcon);
		}
		
		if(item.children) {
			columnLinkText = document.createElement('span');
			columnLinkText.textContent = replaceTemplateTags(item.children);

			columnLink.appendChild(columnLinkText);
		}

		if(item.newTab) {
			columnLink.target = '_blank';

			const externalLinkIcon = returnIconSvg('arrow-up-right-from-square', 'sm', ['blp-footer-column__link-external']);
			columnLink.appendChild(externalLinkIcon);
		}
		columnLinkContainer.appendChild(columnLink);
		columnList.appendChild(columnLinkContainer);
	});

	columnListContainer.appendChild(columnList);
	columnContent.appendChild(columnListContainer);

	return columnContent;
}

function toggleFooterColumnContent(column) {
	const columnListContainer = column.querySelector('.blp-footer-column__list-container');
	const columnTitle = column.querySelector('.blp-footer-column__title');
	const columnList = column.querySelector('.blp-footer-column__list');

	if(columnListContainer.dataset.state == 'closed') {
		columnListContainer.style.setProperty('--blp-footer-column-height', `${columnList.getBoundingClientRect().height}px`);
		columnListContainer.dataset.state = 'open';
		columnTitle.dataset.state = 'open';

		document.querySelectorAll('.blp-footer-column__list-container[data-state="open"]').forEach((item) => {
			if(item != columnListContainer) {
				item.dataset.state = 'closed';
			}
		});
		document.querySelectorAll('.blp-footer-column__title[data-state="open"]').forEach((item) => {
			if(item != columnTitle) {
				item.dataset.state = 'closed';
			}
		});

	}else{
		columnListContainer.dataset.state = 'closed';
		columnTitle.dataset.state = 'closed';
	}
}

function returnFooterPaymentContent() {
	const columnContent = document.createElement('div');
				columnContent.classList.add('blp-footer__payment-options', 'blp-footer-column__content');

	const columnTitle = document.createElement('h3');
				columnTitle.classList.add('blp-footer-column__title');
				columnTitle.textContent = replaceTemplateTags(footerData.popularPaymentOptions);
	
	const columnPaymentItems = document.createElement('ul');
				columnPaymentItems.classList.add('blp-footer__payment-options-list');

	const paymentOptions = [
		{icon: 'amex', alt: 'AMEX'},
		{icon: 'discover', alt: 'Discover'},
		{icon: 'iban', alt: 'IBAN'},
		{icon: 'mastercard', alt: 'Mastercard'},
		{icon: 'paypal', alt: 'PayPal'},
		{icon: 'visa', alt: 'Visa'}
	];

	paymentOptions.forEach((item) => {
		const columnPaymentOption = document.createElement('li');
					columnPaymentOption.classList.add('blp-footer__payment-option');
		const columnPaymentOptionIcon = document.createElement('div');
					columnPaymentOptionIcon.classList.add('blp-icon', 'blp-icon--md');
					columnPaymentOptionIcon.style.setProperty('--bl-headless-icon-url', `url('https://static2.bricklink.com/img/payment-icons/icon-${item.icon}.svg')`);
					columnPaymentOptionIcon.setAttribute('alt', item.alt);
		
		columnPaymentOption.appendChild(columnPaymentOptionIcon);
		columnPaymentItems.appendChild(columnPaymentOption);
	});

	columnContent.appendChild(columnTitle);
	columnContent.appendChild(columnPaymentItems);

	return columnContent;
}

window.initBLPHeader = initBLPHeader;
window.initBLPFooter = initBLPFooter;
window.rebuildIconNav = rebuildIconNav;
window.rebuildCartNav = rebuildCartNav;
window.rebuildSearchDropdown = rebuildSearchDropdown;

function getCookie(cname) {
	const name 	= cname + "=";
	const ca 		= document.cookie.split(';');
	
	for(let i = 0; i < ca.length; ++i) {
		let c = ca[i];
		while(c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if(c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}

	return "";
}

function setCookie(name, value, exdays) {
	if(isCookieEnabled("BLF")) {
		let cookieStr;
				cookieStr	= name + "=" + value;
				cookieStr	+= ";domain=bricklink.com";
				cookieStr	+= ";path=/";
	
		if(exdays != null) {
			let d = new Date();
			d.setTime(d.getTime() + (exdays*24*60*60*1000));
			cookieStr = cookieStr + ";expires=" + d.toUTCString();
		}
	
		document.cookie	= cookieStr;
	}
}


function isCookieEnabled(category) {
	let	cookieSetting	= getCookie('blckCookieSetting');

	cookieSetting	= cookieSetting || "";
	category		= category.toUpperCase();

	if(cookieSetting.indexOf(category) >= 0) {
		return true;
	}

	return false;
}

function getLocalStorage(name) {
	if(localStorage) {
		return localStorage.getItem(name);
	}

	return null;
}
function setLocalStorage(name, value) {
	if(isCookieEnabled("BLF")) {
		if(localStorage) {
			localStorage.setItem(name, value);
		}
	}
}

async function returnTrackingEnvironment() {
	const environment = await fetchEnvironment();

  switch (environment) {
    case 'prod':
      return 'production';
    case 'qa':
      return 'testing';
    case 'dev':
      return 'development';
    default:
      console.error(
        `Unknown environment detected: "${environment}", assuming value of "dev"`,
      );
      return 'development';
  }
};

async function returnTrackingDomain() {
	const environment = await fetchEnvironment();

  switch (environment) {
    case 'prod':
      return 'https://collector.prod.scout.bricklink.com';
    case 'qa':
      return 'https://collector.qa.scout.bricklink.com';
    case 'dev':
      return 'https://collector.dev.scout.bricklink.com';
    default:
      console.error(
        `Unknown environment detected: "${environment}", assuming value of "dev"`,
      );
      return 'https://collector.dev.scout.bricklink.com';
  }
};

;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
	p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
	};p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
	n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//cache2.bricklink.com/vendor/bl-snowplow.js","snowplow"));

async function initSnowPlow() {
	await fetchEnvironment();

	const APP_ID = 'bricklink-legacy';
	const snowPlowEnv = await returnTrackingEnvironment();
	const domain = await returnTrackingDomain();
	const plugins = [];

	if (snowPlowEnv !== 'development') {
		plugins.push(AllowedCountriesPlugin());
	}

	snowplow('newTracker', 'sp', domain, {
		appId: APP_ID,
		cookieSameSite: 'Lax',
		discoverRootDomain: true,
		contexts: {
			session: true,
		},
		anonymousTracking: {
			withSessionTracking: true,
		},
		sessionCookieTimeout: 1800,
		plugins,
	});

	// snowplow('enableActivityTracking', {
	// 	minimumVisitLength: 10,
	// 	heartbeatDelay: 10
	// });

	snowplow('trackPageView');
}

document.addEventListener('DOMContentLoaded', function() {
	if(isCookieEnabled( "TGA" )) {
		initSnowPlow();
	}
});

function blSearchAutocomplete($_inputelem, type, doSearchFunc) {
	return {
		$inputElem:			null
	,	$dropdownElem:		null
	,	hasFocus:			false
	,	fetchSerial:		0
	,	selectedIdx:		-1
	,	lastQuery:			null
	,	resultCnt:			0
	,	fncDoSearch:		null
	,	attachToElem:
			function ($_inputelem, type, doSearchFunc) {
				this.$inputElem	= $($_inputelem);
				this.$inputElem.on( 'keyup change', function ( e ) { this.onInputChange( e ) }.bind( this ) );
				this.$inputElem.focus( function ( e ) { this.onFocus( e ) }.bind( this ) );
				this.$inputElem.blur( function ( e ) { this.onBlur( e ) }.bind( this ) );
				this.$inputElem.on( 'keydown', function ( e ) { this.onKeyDown( e ) }.bind( this ) );

				if(type == 'desktop') {
					this.$dropdownElem		= $(`<div class="blp-adv-search-results">
						<div id="resultContainer" class="blp-adv-search-results__container"></div>
					</div>`);

					this.$dropdownElem.hide();
					this.$inputElem.after( this.$dropdownElem );
				}else{
					this.$dropdownElem = $('#blp-mobile-search-results');
				}
				
				this.fncDoSearch	= doSearchFunc;
			}

	,	onKeyDown:
			function(e) {
				var	newSelectedIdx	= this.selectedIdx;
				
				if ( e.which == 38 )		// Up Arrow
				{
					e.preventDefault();
					newSelectedIdx 	= this.selectedIdx - 1;
					if ( newSelectedIdx < 0 )
						newSelectedIdx	= this.resultCnt - 1;
				}
				else if ( e.which == 40 )	// down Arrow
				{
					e.preventDefault();
					newSelectedIdx	= this.selectedIdx + 1;
					// console.log( newSelectedIdx );
					if ( newSelectedIdx >= this.resultCnt )
						newSelectedIdx	= 0;
					// console.log( newSelectedIdx );
				}
				else if ( e.which == 13 )
				{
					e.preventDefault();

					if ( this.selectedIdx >= 0 )
					{
						var	$sr		= this.$dropdownElem.find( "#resultContainer" ).children().eq( this.selectedIdx );
						var	data	= $sr.data( "data" );

						if ( data.type == 1 )
						{
							this.doSearch( data.keyword );
						}
						else if ( data.type == 2 )
						{
							this.gotoItem( data.item );
						}
					}
					else
					{
						this.doSearch( this.$inputElem.val() );
					}
				}
				else
				{
					newSelectedIdx	= -1;
				}

				if ( newSelectedIdx != this.selectedIdx )
				{
					this.selectedIdx	= newSelectedIdx;
					this.updateStyle();
				}
			}

	,	onInputChange:
			function ( e )
			{
				if ( this.lastQuery == this.$inputElem.val() )
					return;

				var	curSerial	= ++this.fetchSerial;

				setTimeout( function () { this.fetchQuickResult( curSerial ) }.bind( this ), 200 );
			}

	,	onFocus:
			function ( e )
			{
				this.hasFocus	= true;
			}

	,	onBlur:
			function ( e )
			{
				this.hasFocus	= false;
				setTimeout( function () { this.checkAndHideDropDown(); }.bind( this ), 200 );
			}

	,	fetchQuickResult:
			function ( serial )
			{
				if ( this.fetchSerial != serial || !this.hasFocus )
					return;	// Ignore this fetch.

				var		strQuery	= this.$inputElem.val();
				var		strHost		= this.getHost( "www" );

				$.ajax(
					{
						url:	'//' + strHost + '/_ajax/getSearchQuick.ajax'
					,	data:	{ strQuery: strQuery }
					,	cache:	false
					,	method:	"POST"
					,	xhrFields:	{ withCredentials: true }
					}
				).done(
					function ( data )
					{
						if ( data.returnCode != "EC_OK" || !data.hasOwnProperty( "result" ) || data.result == null )
						{
							this.$dropdownElem.hide();
							return;
						}

						this.processResult( data.result );
					}.bind( this )
				).fail(	function () { this.$dropdownElem.hide(); }.bind( this ) );
			}

	,	processResult:
			function ( result )
			{
				var		strQueryCur	= this.$inputElem.val();
				var		results		= [];

				if ( result.strKeyword != strQueryCur )
				{
					this.$dropdownElem.hide();
					return;
				}

				this.lastQuery	= result.strKeyword;

				if ( result.arrKeywordsSuggested && result.arrKeywordsSuggested.length > 0 )
				{
					$.each( 
						result.arrKeywordsSuggested.slice( 0, 3 )
					,	function ( i, v  ) { results.push( { type: 1, keyword: v } ) }
					);
				}

				if ( result.arrItems && result.arrItems.length > 0 )
				{
					for ( var i = 0; i < result.arrItems.length; ++i )
					{
						var	r	= result.arrItems[ i ];

						results.push( { type: 2, item: r.dmItem, img: r.dmImg, idColor: r.idColor } );

						if ( i == 5 )
							break;
					}
				}

				this.buildResultPane( results );
			}

	,	buildResultPane:
			function ( results )
			{
				var $resultContainer	= this.$dropdownElem.find( "#resultContainer" );

				$resultContainer.empty();

				$.each( 
					results
				,	function ( i, v ) 
					{
						var	$newElem	= $(`<div class="js-sac-row blp-adv-search-results__row"></div>`);

						$newElem.attr( 'data-idx', i );
						$newElem.hover(
							function ( e )
							{
								var newIdx	= parseInt( $( e.target ).attr( "data-idx" ) );
								if ( newIdx >= 0 && newIdx < this.resultCnt )
								{
									this.selectedIdx	= newIdx;
									this.updateStyle();
								}
							}.bind( this )
						);

						$newElem.data( "data", v );
						
						if ( v.type == 1 )
						{
							$newElem.text( v.keyword ).click( function ( e ) { e.preventDefault(); this.doSearch( v.keyword ); }.bind( this ) );
							$resultContainer.append( $newElem );
						}
						else if ( v.type == 2 )
						{
							var $innerDiv	= $(`<div class="blp-adv-search-results__item-details"><span id="sac-item-name" class="blp-adv-search-results__item-name"></span><span id="sac-item-no" class="blp-adv-search-results__item-number"></span></div>`);

							$innerDiv.find( "#sac-item-name" ).text( v.item.strItemName );
							$innerDiv.find( "#sac-item-no" ).text( "Item No: " + v.item.strItemNoFull );

							$newElem.append( $( `<img class="blp-adv-search-results__item-image" style="width: 40px; height: auto;"/>` ).attr( "src", this.getItemImgUrl( v.img ) ) );
							$newElem.append( $innerDiv );
							$newElem.click( function ( e ) { e.preventDefault(); this.gotoItem( v.item, v.idColor ); }.bind( this ) );
							$resultContainer.append( $newElem );
						}
					}.bind( this )
				);

				this.selectedIdx	= -1;
				this.resultCnt		= results.length;
				this.$dropdownElem.show();
			}

	,	checkAndHideDropDown:
			function ()
			{
				if ( !this.hasFocus )
				{
					this.$dropdownElem.hide();
				}
			}

	, 	updateStyle:
			function ()
			{
				var		$rows	= this.$dropdownElem.find( ".js-sac-row" );

				$rows.each(
					function ( i, r )
					{
						var $r	= $( r );
						if(i == this.selectedIdx) {
							$r.addClass('blp-adv-search-results__row--selected');
						}else{
							$r.removeClass('blp-adv-search-results__row--selected');
						}
					}.bind( this )
				);
			}


	,	doSearch:
			function ( strVal )
			{
				if ( this.fncDoSearch != null )
					this.fncDoSearch( strVal );
				else
					console.log('No search function defined');
				this.$dropdownElem.hide();
			}

	,	gotoItem:
			function ( item, idColor )
			{
				var	url	= "//" + this.getHost( "www" ) + "/v2/catalog/catalogitem.page?" + item.typeItem + "=" + item.strItemNoFull;

				if ( idColor != null && idColor >= 0 )
					url	+= "&idColor=" + idColor;

				window.location.href	= url;
			}

	,	getHost:
			function ( host )
			{
				if ( window.hasOwnProperty( "blapp" ) )
					return blapp.config.getHost( host );
				else if ( window.hasOwnProperty( "bl" ) )
					return bl.util.getBLHost( host );
				return host + ".bricklink.com";
			}

	,	getItemImgUrl:
			function ( dmImg )
			{
				if ( dmImg == null || !dmImg.bHasFile )
				{
					if ( dmImg != null && dmImg.typeItem == "M" )
						return "//" + this.getHost( "static" ) + "/clone/img/no_image_m.png";
					return "//" + this.getHost( "static" ) + "/clone/img/no_image.png";
				}

				var	url	= "//" + this.getHost( "img" ) + "/ItemImage/";

				if ( dmImg.hasOwnProperty( "idImg" ) && dmImg.idImg > 0 )
				{
					url	+= "EXTT/" + dmImg.idImg + ".t1.png";
				}
				else
				{
					url	+= dmImg.typeItem + "T/" + dmImg.idColor + "/" + dmImg.strItemNoFull + ".t1.png";
				}

				if ( dmImg.nRevision > 0 )
					url	+= "?" + dmImg.nRevision;

				return url;
			}
	};
};